import React, { useState, useEffect } from "react";
import Editor from "react-simple-wysiwyg";
import { Button } from "components/globals/ui/input/Button";
import Spinner from "components/globals/ui/Spinner";
import { FaAngleDown } from "react-icons/fa";
import * as Switch from "@radix-ui/react-switch";
import { Link } from "react-router-dom";

export const PSInputGroup = function ({ children }) {
  return (
    <>
      <div className="my-sm">{children}</div>
    </>
  );
};

export const PSLabel = function ({ value, required }) {
  return (
    <>
      <div className="font-bold uppercase text-[12px] mb-2">
        <span>{value}</span>
        {required && <span className="text-warning inline-block ml-1">*</span>}
      </div>
    </>
  );
};

export function PSInputBase({
  onChange,
  name,
  label,
  type = "text",
  required = false,
  context,
  value,
}) {
  const handleChange = (e) => {
    if (onChange) {
      onChange(context, e.target.value);
    }
  };

  return (
    <PSInputGroup>
      <PSLabel value={label} required={required} />
      <input
        className="
        bg-secondary 
        border-[1px] 
        border-inputBorder
        rounded-lg
        py-3 px-3
        block
        w-full"
        name={name}
        type={type}
        value={value}
        required={required}
        onChange={handleChange}
      />
    </PSInputGroup>
  );
}

export function PSWysiwyg({ onChange, name, label, required, context, value }) {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(value || "");
  }, [value]);

  const handleTextChange = (e) => {
    const updatedText = e.target.value;
    setText(updatedText);
    onChange(context, updatedText);
  };

  return (
    <div className="my-5">
      <PSLabel value={label} required={required} />
      <div 
        className="
          border-[1px] 
          border-inputBorder 
          rounded-lg 
          overflow-hidden"
        >
        <Editor id={name} value={text} onChange={handleTextChange} />
      </div>

    </div>
  );
}

export function PSFormControls({
  data,
  loading,
  edit,
  label,
  path,
  onSubmitForm,
}) {
  return (
    <div className="
      fixed
      bottom-0
      left-0
      w-full
      px-[40px]
      py-3
      z-[10]
      bg-secondary
      border-t-[1px]
      border-inputBorder">
      <div className="flex justify-end">
        <div className="flex items-center">
          {edit && (
            <Link
              to={`/${path}/${data?.entry?.internal_id}`}
              className="mr-2"
            >
              <Button variant="outline">Cancel</Button>
            </Link>
          )}
          <PSSubmit
            onClick={onSubmitForm}
            loading={loading}
            label={
              loading
                ? edit
                  ? "Updating..."
                  : "Creating..."
                : edit
                ? `Update ${label}`
                : `Create ${label}`
            }
            disabled={loading}
          />
        </div>    
      </div>
    </div>
  );
};

export function PSSubmit({ loading, label, disabled, onClick }) {
  return (
    <>
      <div className="relative py-3">
        <Button variant="primary" disabled={disabled} onClick={onClick}>
          {loading ? <div className="px-md"><Spinner /></div> : label}
        </Button>
      </div>
    </>
  );
}

export function PSSelect({
  label,
  firstItemLabel,
  children,
  name,
  onChange,
  value,
}) {
  const [selectedValue, setSelectedValue] = useState(value || "");

  useEffect(() => {
    setSelectedValue(value || "");
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    onChange(name, newValue);
  };

  return (
    <>
      <PSLabel value={label} />
      <div className="relative">
        <FaAngleDown
          className="
            absolute 
            z-[2] 
            right-4 
            top-2 
            translate-y-1/2"
        />
        <select
          name={name}
          value={selectedValue}
          className="
            bg-secondary 
            border-[1px] 
            border-inputBorder
            rounded-lg
            py-3 pl-3 pr-10
            block
            w-full
            appearance-none
            relative
          "
          onChange={handleChange}
        >
          <option value="">--- {firstItemLabel} ---</option>
          {children}
        </select>
      </div>
    </>
  );
}

export function PostTypes({ data, onInputChange, postType }) {
  return (
    <div className="mb-3 pb-1 border-b-[1px] border-border">
      {data.map((type) => (
        <Button
          className="mr-2 mb-2"
          key={type.key}
          variant="ghost"
          onClick={() => onInputChange("type", type.key)}
          active={postType === type.key}
        >
          {type.label}
        </Button>
      ))}
    </div>
  );
}

export function PostCategories({
  data,
  postType,
  onInputChange,
  postCategory,
}) {
  return (
    <div className="mb-3 pb-1 border-b-[1px] border-border">
      {data
        .filter((category) => category.type === postType)
        .flatMap((category) => category.items)
        .map((item) => (
          <Button
            active={postCategory === item.key}
            className="mr-2 mb-2"
            key={item.key}
            variant="ghost"
            onClick={() => onInputChange("category", item.key)}
          >
            {item.label}
          </Button>
        ))}
    </div>
  );
}

export function PSLightSwitch({ label, name, onChange, value = false }) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = (newValue) => {
    setChecked(newValue);
    onChange(name, newValue);
  };

  return (
    <>
      <PSLabel value={label} />
      <div className="flex items-center gap-2">
        <Switch.Root
          checked={checked}
          onCheckedChange={handleChange}
          className="relative w-10 h-5 bg-secondary rounded-full transition duration-300 
                    outline-none cursor-pointer"
        >
          <Switch.Thumb
            className="absolute left-1 top-1 w-3 h-3 bg-brg rounded-full 
                        transition-transform duration-300 translate-x-0 
                        data-[state=checked]:translate-x-5 data-[state=checked]:bg-tangerine"
          />
        </Switch.Root>
      </div>
    </>
  );
}
