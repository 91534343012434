export const Schema = {
  private: "",
  gallery: "",
  banners: "",
  title: "",
  body: "",
  type: "",
  category: "",
	region: "",
};

export const types = [

  {
    key: "regional",
    label: "Regional",
  },
  {
    key: "national",
    label: "National",
  },
  {
    key: "single",
    label: "Single Make",
  },
  {
    key: "type",
    label: "Car Type",
  },
  {
    key: "other",
    label: "Other",
  },
];

export const categories = [
  {
    type: "regional",
    items: [],
  },
  {
    type: "national",
    items: [],
  },
  {
    type: "single",
    items: [],
  },
  {
    type: "type",
    items: [
      {
        key: "sports",
        label: "Sports Cars",
      },
      {
        key: "race",
        label: "Race Cars",
      },
      {
        key: "overland",
        label: "Overland/Offroad",
      },
      {
        key: "vintage",
        label: "Vintage",
      },
      {
        key: "modern",
        label: "Modern",
      },
      {
        key: "other",
        label: "Other",
      },

    ],
  },
  {
    type: "other",
    items: [],
  },
];
