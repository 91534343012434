import { React } from "react";
import Single from "components/globals/image/Single";

const ORSDataBadge = ({ title, image }) => {
  return (
		<>
			<div className="inline-flex items-center pr-2 bg-black text-white border-[1px] border-black transition rounded-[4px] overflow-hidden">
				<div className="relative w-[35px] h-[25px] overflow-hidden">
					<Single image={image} />
				</div>
				<span className="font-bold ml-2 text-[11px]">
					{title}
				</span>
			</div>
		</>
  );
};

export default ORSDataBadge;
