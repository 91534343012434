import React from "react";
import { useLikes } from "providers/LikesContext"; 
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { useAuth } from "providers/AuthContext";

export default function Likes({ entry_id, entry_type, opacity }) {
  const { userInfo, isFetching } = useAuth();
  const { likes, updateLikes, loading: likesLoading } = useLikes();
  const isLoggedIn = !!userInfo;

  // Find the like data directly when rendering
  const currentLikeData = likes.find((like) => like.document_id === entry_id);
  const isLiked = currentLikeData?.users?.includes(userInfo?.user_id) || false;
  const likeCount = currentLikeData?.users?.length || 0;

  const handleLike = () => {
    if (!likesLoading) {
      updateLikes(isLiked ? 'unlike' : 'like', entry_id, entry_type);
    }
  };

  // Don't render if likes are still loading
  if (likesLoading || isFetching) return null;

  return (
    <>
      {isLoggedIn && (
        <div className="m-1 text-[12px] md:text-[16px] px-2 md:py-1 md:px-3 relative rounded-full overflow-hidden h-[25px] md:h-[31px] inline-flex items-center">
          <div className={`absolute inset-0 bg-secondary ${opacity ? 'opacity-[.7]' : ''}`}></div>

          <div className="relative z-[2] flex items-center">
            <span className="inline-block flex items-center cursor-pointer" onClick={handleLike}>
              {isLiked ? <FaHeart className="text-red" /> : <FaRegHeart />}
              <span className="inline-block text-black font-bold ml-1 my-0 ">
                {likeCount < 100 ? likeCount : '100+'}
              </span>
            </span>
          </div>
        </div>
      )}
    </>
  );
}