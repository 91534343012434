import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetUserEntryQuery } from "services/auth/authService";
import {
  types,
  categories,
  Schema,
  associationTypes,
  associationState,
  idMap,
} from "types/postTypes";
import {
  formatTitle,
  scrollToTop,
  fetchAndMapAssociations,
  clearPostData,
  loadExistingData,
  setCategory,
  createFormData,
} from "helpers/utils";
import PSPage from "components/globals/ui/layout/Page";
import { EntryLoading, EntryError } from "components/globals/EntryStates";
import {
  PostTypes,
  PostCategories,
  PSInputBase,
  PSWysiwyg,
  PSFormControls,
  PSSelect,
  PSLightSwitch,
} from "components/globals/ui/input/PSInputs";
import PSGallery from "components/globals/PSGallery";
import { useDispatch } from "react-redux";
import { createEntry, updateEntry } from "store/authSlice";
import { SuccessPane, ErrorPane } from "components/modals/Panes";
import PSMakeAndModel from "components/globals/ui/input/PSMakeAndModel";

import {
  PSCollapsableContainer,
  PSCollapsableContainerHeading,
  PSCollapsableContainerContent,
} from "components/globals/ui/PSCollapsableContainer";

/*
CREATE POST
postData: the data assembled that will be sent to the backend
existingEntryData: the post being edited, set to postData with effect
*/

export default function CreatePost({ edit = false }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [postData, updatePostData] = useState(Schema);
  const userToken = useSelector((state) => state.auth.userToken);
  const dispatch = useDispatch();

  const {
    data: existingEntryData,
    loading: entryLoading,
    error: entryError,
  } = useGetUserEntryQuery(edit ? `post/${id}` : null, { skip: !edit });

  const [associations, setAssociations] = useState(associationState);

  const updateAssociation = (type, value) => {
    setAssociations((prev) => ({ ...prev, [type]: value }));
  };

  // load existing data if editing
  useEffect(() => {
    if (edit && existingEntryData) {
      loadExistingData(updatePostData, Schema, existingEntryData);
    }
  }, [edit, existingEntryData]);

  // reset when navigating here
  useEffect(() => {
    if (!edit) {
      reset();
    }
  }, [edit]);

  // new post, set the first 2
  useEffect(() => {
    if (!edit) {
      setNewPostDataProperty("type", types[0]?.key);
      setCategory(setNewPostDataProperty, categories, types[0]?.key);
    }
  }, []);

  // always default to the first category in the list when
  // updating the post type
  useEffect(() => {
    if (postData.type) {
      setCategory(setNewPostDataProperty, categories, postData.type);
    }
  }, [postData.type]);

  // load all the association data into select boxes
  useEffect(() => {
    const loadAssociations = async () => {
      try {
        const results = await fetchAndMapAssociations(
          userToken,
          associationTypes
        );
        results.forEach(({ type, data }) => {
          updateAssociation(type, data);
        });
        setLoading(false);
      } catch (err) {
        setErrorMessage(err.message);
      }
    };

    loadAssociations();
  }, [userToken]);

  // coming from input onChange (text and select, type, category)
  const onInputChange = (context, val) => {
    setNewPostDataProperty(context, val);
  };

  const updateSelectedFiles = (images) => {
    setNewPostDataProperty("gallery", images);
  };

  const setNewPostDataProperty = (context, val) => {
    updatePostData((prevData) => ({
      ...prevData,
      [context]: val,
    }));
  };

  // SUBMIT
  const submitForm = async () => {
    if (!userToken || (edit && !id)) return;

    if (!postData.title || postData.title.length <= 0) {
      alert("title required");
      return;
    }

    // Set initial state for loading, messages, etc.
    setLoading(true);
    setErrorMessage(null);

    // create the form data for processing
    let fd = createFormData(postData, Schema);

    if (edit) {
      fd.append("internal_id", id);
    }

    try {
      const action = edit
        ? updateEntry({ data: fd, entry_type: "post" })
        : createEntry({ data: fd, entry_type: "post" });
      await dispatch(action).unwrap();
      setSuccess(true);
    } catch (err) {
      setErrorMessage(err.message || "Failed. Please try again.");
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  const reset = () => {
    clearPostData(updatePostData, Schema);
    setLoading(false);
    setErrorMessage(null);
    setResetRequested(true);
  };

  const handleResetComplete = () => {
    setResetRequested(false);
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };

  const onMakeAndModelChange = ({ make, model }) => {
    setNewPostDataProperty('make', make);
    setNewPostDataProperty('model', model);
  };

  if (entryLoading) {
    return <EntryLoading />;
  }
  if (entryError) {
    return <EntryError msg="Failed to load." />;
  }

  return (
    <PSPage
      header={`${edit ? "Edit" : "New"} Post - ${postData?.type || ""} - ${
        postData?.category || ""
      }`}
      key={edit ? id : "new"}
    >
      <SuccessPane
        edit={edit}
        visible={success}
        title={`Successfully ${edit ? "Updated" : "Created"} Post: ${
          postData?.title
        }`}
      />

      <ErrorPane
        visible={errorMessage}
        message={errorMessage}
        onClose={clearErrorMessage}
      />

      <PostTypes
        postType={postData?.type}
        data={types}
        onInputChange={onInputChange}
      />

      <PostCategories
        postType={postData?.type}
        postCategory={postData?.category}
        data={categories}
        onInputChange={onInputChange}
      />

      <PSLightSwitch
        name="private"
        label="Mark Private?"
        value={postData["private"] || false}
        onChange={onInputChange}
      />

      <PSInputBase
        name="title"
        label="Title"
        type="text"
        context="title"
        required
        value={postData.title || ""}
        onChange={onInputChange}
      />

      <PSGallery
        initialImages={postData.gallery || []}
        onImagesChange={updateSelectedFiles}
        resetRequested={resetRequested}
        onResetComplete={handleResetComplete}
      />

      <PSWysiwyg
        name="body"
        label="Body"
        context="body"
        value={postData.body || ""}
        onChange={onInputChange}
      />

      <PSCollapsableContainer startShown={false}>
        <PSCollapsableContainerHeading>
          <div className="py-3">
            <h3 className="font-bold text-2xl">Custom Car Info</h3>
            <p className="mt-2 italic font-mono max-w-[500px] text-[13px]">NOTE: You want to associate this with a car in your garage, you can select your car below instead of using these fields.</p>    
          </div>
        </PSCollapsableContainerHeading>

        <PSCollapsableContainerContent>
          <div>
            <PSInputBase
            name="year"
            label="Year"
            type="year"
            context="year"
            value={postData.year || ""}
            onChange={onInputChange}
          />

          <PSMakeAndModel
            initialMake={postData.make || ""}
            initialModel={postData.model || ""}
            onChange={onMakeAndModelChange}
          />

          <PSInputBase
            name="trim"
            label="Trim"
            type="text"
            context="trim"
            value={postData.trim || ""}
            onChange={onInputChange}
          />

          <PSInputBase
            name="color"
            label="Color"
            type="text"
            context="color"
            value={postData.color || ""}
            onChange={onInputChange}
          />
          </div>
        </PSCollapsableContainerContent>
      </PSCollapsableContainer>


      <PSCollapsableContainer startShown={true}>
        <PSCollapsableContainerHeading>
          <div className="py-3">
            <h3 className="font-bold text-2xl">Associations</h3>
            <p className="mt-2 italic font-mono max-w-[500px] text-[13px]">If you want to associate your post with other items you've made, you can do that here.</p>    
          </div>
        </PSCollapsableContainerHeading>

        <PSCollapsableContainerContent>
          {/* LOOP THROUGH ASSOCIATIONS */}
          {associationTypes.length > 0 && !loading && (
              <>
                {associationTypes.map((association) => {
                  const items = associations[association] || [];

                  return (
                    <div className="my-2" key={association}>
                      <PSSelect
                        name={`${idMap[association]}_id`}
                        label={association}
                        firstItemLabel={`Associate with your ${association}`}
                        value={postData[`${idMap[association]}_id`] || ""}
                        onChange={onInputChange}
                      >
                        {items.map((item) => (
                          <option value={item.internal_id} key={item.internal_id}>
                            {association === "garage"
                              ? `${formatTitle(item.title)} (${item.year} ${
                                  item.make
                                } ${item.model})`
                              : formatTitle(item.title)}
                          </option>
                        ))}
                      </PSSelect>
                    </div>
                  );
                })}
              </>
            )}
        </PSCollapsableContainerContent>
      </PSCollapsableContainer>

 

      {/* Bottom bar with form submission controls */}
      <PSFormControls 
        data={existingEntryData}
        loading={loading}
        edit={edit} 
        label="Post" 
        path="post" 
        onSubmitForm={submitForm}
      />

    </PSPage>
  );
}
