import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export const ORSGridLinks = function ({ children }) {
	return (
		<>
			<div className="flex items-stretch w-full flex-wrap md:flex-nowrap gap-xs">
				{children}
			</div>
		</>
	);
};

export const ORSGridLink = function ({ bg, link, text }) {
	return (
		<>
			<NavLink 
				className={`bg-${bg} group hover:bg-brg hover:text-white transition rounded-default p-2 md:p-3 flex items-center text-black w-full md:w-1/2`}
				to={link}
			>
				<span className="text-2xl font-serif grow">{text}</span>
				<FaChevronRight />
			</NavLink>
		</>
	);
};
