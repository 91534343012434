import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PSPage from "components/globals/ui/layout/Page"
import { formatDate, useFetchAuthedData } from "helpers/utils";
import parse from "html-react-parser";
import IsYoursBanner from 'components/globals/IsYoursBanner'
import { NavLink } from "react-router-dom";
import CarBadge from "components/globals/car/badge";
import Gallery from "components/globals/image/Gallery";


const ProjectPage = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { id } = useParams();

  // double entry, entry.entry & entry.user
	const { data: entry, loading, error } = useFetchAuthedData(
		`project/${id}`,
		[id],
	);

  useEffect(() => {
    setFormattedDate(formatDate(entry?.entry?.created_at));
  }, [entry]);

  // Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <PSPage header={`Project: ${entry.entry.title}`}>

        <IsYoursBanner label="Your Project" entry={entry.entry} />

        <h1 className="text-3xl font-bold">{ entry.entry.title }</h1>
        <p>{formattedDate}</p>
        <NavLink to="/dashboard/overview" className="text-accent underline">
          View all of your projects
        </NavLink>

        <Gallery gallery={entry.entry.gallery} />
        
        {entry?.entry?.body && (
          <div className="mt-3 ors-rich-text">{parse(entry?.entry?.body)}</div>
        )}

        {entry.entry.car_id && (
          <>
            <p>For Car:</p>
            <CarBadge car_id={entry.entry.car_id} />
          </>
        )}

      </PSPage>
    </>
    
  );
};

export default ProjectPage;
