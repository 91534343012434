import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";
import { useParams } from "react-router-dom";
import {ORSMasterListing} from "components/globals/ORSMasterListing"


export default function CarsBrand() {
	const { brand, model } = useParams();

	const sections = [
    { 
      params: {
				elementId: "carsByBrand", 
				headingTitle: `${brand} ${model} cars on the site`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `garage/related/make/${brand}/model/${model}`,
				omit: "none",
				limit: "12",
				type: "car", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    },
		{ 
      params: {
				elementId: "listingsByBrand", 
				headingTitle: `${brand} ${model} parts for sale`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/listing/make/${brand}/model/${model}`,
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    },
		{ 
      params: {
				elementId: "wantsByBrand", 
				headingTitle: `${brand} ${model} want-ads`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/want/make/${brand}/model/${model}`,
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    }
  ]


	return (
		<>

			<ORSSubnav>
				<SubnavItem to="/cars" label="Cars Home" />
				<SubnavItem active to="/cars/brands" label="Brands" />
				<SubnavItem to="/cars/users" label="User Cars" />
				<SubnavItem to="/cars/spotted" label="Spotted" />
			</ORSSubnav>

			<ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/cars" label="Cars" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/cars/brands" label="Brands" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to={`/cars/brands/${brand}`} label={brand} />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label={model} />
			</ORSBreadcrumbs>

			
			<div className="bg-brg text-white py-12">
				<div className="w-main px-3">
					<span className="uppercase font-bold text-lg tracking-[0.1em] mb-2">{brand}</span>
					<h1 className="text-4xl md:text-6xl font-serif">{model}</h1>
				</div>
			</div>
			<PSPage>
				{sections.map(({params, displayOptions}) => (
					<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
				))}
			</PSPage>
		</>
	);
};
