export const Schema = {
  private: "",
  gallery: "",
  banners: "",
  title: "",
  body: "",
  type: "",
  category: "",
  group_id: "",
  event_id: "",
  engine: "",
  mileage: "",
  horsepower: "",
  torque: "",
  condition: "",
  year: "",
  make: "",
  model: "",
  trim: "",
  color: "",
};

export const associationTypes = ["events", "groups"];

export const idMap = {
  groups: "group",
  events: "event",
};

export const associationState = {
  groups: "",
  events: "",
};

export const types = [
  {
    key: "daily",
    label: "Daily Driver",
  },
  {
    key: "weekend",
    label: "Weekend Warrior",
  },
  {
    key: "project",
    label: "Project Car",
  },
  {
    key: "garage-queen",
    label: "Garage Queen",
  },
  {
    key: "part-out",
    label: "Part Out",
  },
  {
    key: "other",
    label: "Other",
  },
];

export const categories = [
  {
    type: "daily",
    items: [
      {
        key: "groceryGetter",
        label: "Grocery Getter",
      },
      {
        key: "beater",
        label: "Beater",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },
  {
    type: "weekend",
    items: [
      {
        key: "carsAndCoffee",
        label: "Cars & Coffee Machine",
      },
      {
        key: "canyonCarver",
        label: "Canyon Carver",
      },
    ],
  },
  {
    type: "project",
    items: [
      {
        key: "hopefulRestoration",
        label: "Hopeful Restoration",
      },
      {
        key: "lostCause",
        label: "Lost Cause",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },

  {
    type: "garage-queen",
    items: [
      {
        key: "concours",
        label: "Concours",
      },
      {
        key: "specialOccasion",
        label: "Special Occasion Car",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },

  {
    type: "part-out",
    items: [],
  },

  {
    type: "other",
    items: [],
  },
];

export const getTypeString = function (string) {
  const match = types.find((type) => type.key === string);
  return match ? match.label : "";
};

export const getCategoryString = function (type, string) {
  const match = categories.find((category) => category.type === type);
  if (!match) return ""; 

  const catMatch = match.items.find((item) => item.key === string);
  return catMatch ? catMatch.label : "";
};