import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCredentials, logout } from 'store/authSlice';
import { useGetUserDetailsQuery } from 'services/auth/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { userInfo: reduxUserInfo } = useSelector((state) => state.auth);
  const [userInfo, setUserInfo] = useState(reduxUserInfo);
  const [isFetching, setIsFetching] = useState(true);
  const dispatch = useDispatch();

  // Check if token exists in localStorage
  const token = localStorage.getItem('userToken');

  const { data, error } = useGetUserDetailsQuery('userDetails', {
    pollingInterval: 900000,
    skip: !token,
  });

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setUserInfo(null);
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if(!data) { 
      setIsFetching(false);
      return;
    }
    setUserInfo(data);
    dispatch(setCredentials(data));
    setIsFetching(false);
    
  }, [data, error, dispatch, handleLogout]);

  useEffect(() => {
    setUserInfo(reduxUserInfo);
  }, [reduxUserInfo]);

  
  return (
    <AuthContext.Provider value={{ userInfo, isFetching, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);