import { React, useEffect, useState } from "react";
import PSPage from "components/globals/ui/layout/Page"
import { useForm, FormProvider } from 'react-hook-form';
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { registerUser } from 'store/authSlice'
import { Button } from "components/globals/ui/input/Button"
import PSInputText from "components/globals/ui/input/PSInputText";
import { PSSubmit } from "components/globals/ui/input/PSInputs";
import SingleImageUploader from "components/globals/ui/image/SingleImageUploader"
import { prepareFormData } from "helpers/utils";
import { StandardErrorMessage } from "components/globals/messages/StandardMessages";
import PSFormLightswitch from "components/globals/ui/input/PSFormLightswitch";

export default function Register() {
	const { loading, userInfo, error, success } = useSelector(
		(state) => state.auth
	)
	const dispatch = useDispatch()
	const form = useForm({
		mode: 'onBlur' // This enables validation on blur
	});
	const { handleSubmit, reset, register, setError, formState, setValue, clearErrors, watch } = form;
	const { errors, isValid } = formState;
	const navigate = useNavigate();
	
	// State to track username and email validation status
	const [usernameExists, setUsernameExists] = useState(false);
	const [emailExists, setEmailExists] = useState(false);

	useEffect(() => {
		if (success) navigate('/login?success=true')
		if (userInfo) navigate('/dashboard/overview')  
	}, [navigate, userInfo, success])

	// Handler for username validation
	const checkUsername = async (username) => {
		if (!username) return;
		
		// Trim any trailing spaces
		const trimmedUsername = username.trim();
		
		// If the original had trailing spaces, update the field value
		if (trimmedUsername !== username) {
			setValue('username', trimmedUsername);
		}
		
		// Check for spaces within the username
		if (trimmedUsername.includes(' ')) {
			setError('username', {
				type: 'manual',
				message: 'No spaces allowed in username'
			});
			return;
		}
		
		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URI}/api/users/checkUsername`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ username: trimmedUsername }),
			});
			const data = await response.json();
			
			if (data.msg === 'true') {
				setUsernameExists(true);
				setError('username', {
					type: 'manual',
					message: 'This username is already taken'
				});
			} else {
				setUsernameExists(false);
				clearErrors('username');
			}
		} catch (err) {
			console.error('Error checking username:', err);
		}
	};

	// Handler for email validation
	const checkEmail = async (email) => {
		if (!email) return;
		
		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URI}/api/users/checkEmail`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			});
			const data = await response.json();
			
			if (data.msg === 'true') {
				setEmailExists(true);
				setError('email', {
					type: 'manual',
					message: 'This email address is already registered'
				});
			} else {
				setEmailExists(false);
				clearErrors('email');
			}
		} catch (err) {
			console.error('Error checking email:', err);
		}
	};

	const submitForm = async (data) => {
		// Final check for username spaces before submission
		if (data.username.includes(' ')) {
			setError('username', {
				type: 'manual',
				message: 'No spaces allowed in username'
			});
			return;
		}
		
		// Make sure username is trimmed
		data.username = data.username.trim();
		
		// check if passwords match
		if (data.password !== data.confirmPassword) {
			setError('confirmPassword', {
				type: 'manual',
				message: 'Passwords do not match'
			});
			return;
		}
		
		const formData = prepareFormData(data); 
		dispatch(registerUser(formData));
	};

	// Determine if submit button should be disabled
	const isSubmitDisabled = usernameExists || emailExists || Object.keys(errors).length > 0 || !isValid;

	return (
		<>
			<PSPage>
				<div className="max-w-[600px] w-main mt-lg">
					<FormProvider {...form}>
						<form onSubmit={handleSubmit(submitForm)}>

							<PSInputText
								register={form.register}
								name="username"
								label="Username"
								type="text"
								error={errors.username?.message}
								onBlur={(e) => checkUsername(e.target.value)}
								required
							/>

							<PSInputText
								register={form.register}
								name="firstName"
								label="First Name"
								type="text"
								required
							/>

							<PSInputText
								register={form.register}
								name="lastName"
								label="Last Name"
								type="text"
								required
							/>

							<PSInputText
								register={form.register}
								name="email"
								label="Email Address"
								type="email"
								error={errors.email?.message}
								onBlur={(e) => checkEmail(e.target.value)}
								required
							/>

							<PSFormLightswitch
								name="allowEmail"
								label="Email available on user profile, parts listings and want-ads?"
								register={form.register}
							/>

							<PSInputText
								register={form.register}
								name="password"
								label="Password"
								type="password"
								required
							/>

							<PSInputText
								register={form.register}
								name="confirmPassword"
								label="Confirm Password"
								type="password"
								error={errors.confirmPassword?.message}
								required
							/>

							<PSInputText
								register={form.register}
								name="zip"
								label="Zip Code"
								type="number"
								required
							/>

							<p className="text-xl font-bold mt-3 mb-1">Profile Photo</p>
							<SingleImageUploader register={register} reset={reset} />
							
							<PSSubmit 
								loading={loading} 
								label='Create Account' 
								disabled={isSubmitDisabled}
							/>

							<NavLink to='/login'>
								<Button variant='link'>Login if you already have an account</Button>
							</NavLink>
							{error && <StandardErrorMessage msg={error} />}

						</form>
					</FormProvider>
					
				</div>
			</PSPage>
		</>
	);
};