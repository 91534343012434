import React from "react";
import TypeBadge from "components/globals/ui/TypeBadge";

export default function PSCardImageContainer({children, item, displayOptions}) {
  return (
    <>
      <div className="relative rounded-md overflow-hidden aspect-[4/2.75]">
        {displayOptions.typeBadge && (
          <div className="absolute
            top-2
            left-2 
            z-[2]">
            <TypeBadge entryType={item.entry_type} type={item.type} category={item.category} />
          </div>
        )}
				{children}
      </div>
    </>
  );
};
