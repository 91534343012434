import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGetUserEntryQuery } from 'services/auth/authService'; 
import { useSelector } from "react-redux";

const LikesContext = createContext();

export const LikesProvider = ({ children }) => {
  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = useSelector((state) => state.auth.userToken);
  const { data, error, isLoading } = useGetUserEntryQuery('likes/all', {
    skip: !userToken
  });

  useEffect(() => {
    if (data) {
      const likeData = Array.isArray(data) ? data : [];
      setLikes(likeData);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error('Error fetching likes:', error);
      setLoading(false);
    }
  }, [error]);

  const updateLikes = async (type, entry_id, entry_type) => {
    if (!userToken) return;
  
    const endpoint =
      type === 'like'
        ? `${process.env.REACT_APP_SERVER_URI}/api/likes/like`
        : `${process.env.REACT_APP_SERVER_URI}/api/likes/unlike`;
    const method = 'POST';
  
    try {
      const res = await fetch(endpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ document_id: entry_id, document_type: entry_type }),
      });
  
      if (!res.ok) throw new Error(`Error ${type === 'like' ? 'liking' : 'unliking'} post`);
      const data = await res.json();
  
      if (!data) return;
  
      setLikes((prevLikes) => {
        const index = prevLikes.findIndex((like) => like.document_id === data.document_id);
        if (index !== -1) {
          return prevLikes.map((like, i) => (i === index ? data : like));
        } else {
          return [...prevLikes, data];
        }
      });
    } catch (err) {
      console.error(`Error ${type}ing the post:`, err);
    }
  };

  return (
    <LikesContext.Provider value={{ likes, updateLikes, loading }}>
      {children}
    </LikesContext.Provider>
  );
};

export const useLikes = () => {
  const context = useContext(LikesContext);
  if (!context) {
    throw new Error('useLikes must be used within a LikesProvider');
  }
  return context;
};