import { React } from "react";
import { Link } from "react-router-dom";
import { useFetchData } from "helpers/utils";
import DataBadge from "components/globals/ui/ORSDataBadge"

const UserBadge = ({ userId, noLink, noPadding, noUserName }) => {
  const {
    data: user,
    loading,
    error,
  } = useFetchData(
    `${process.env.REACT_APP_SERVER_URI}/api/users/publicUserById/${userId}`,
    [userId]
  );

  // Loading & error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      {noLink ? (
        <DataBadge 
          image={user.gallery?.[0] || null} 
          title={user.username} 
        />
      ) : (
        <Link
          to={{ pathname: `/users/${user.username}` }}
        >
          <DataBadge 
            image={user.gallery?.[0] || null} 
            title={user.username} 
          />
        </Link>
      )}
    </>
  );
};

export default UserBadge;
