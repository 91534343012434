import { useFetchData } from "helpers/utils";
import ORSYearMakeModel from "components/globals/car/ORSYearMakeModel";

const CarDetails = ({ car_id }) => {
  // If car_id is present, fetch data; otherwise, use manualData
  const {
    data: fetchedEntry,
    loading,
    error,
  } = useFetchData(
    car_id ? `${process.env.REACT_APP_SERVER_URI}/api/car/${car_id}` : null,
    [car_id]
  );

  const entry = fetchedEntry?.entry;

  // Loading & error states
  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error: {error.message}</span>;

  return (
    <ORSYearMakeModel entry={entry} />
  );
};

export default CarDetails;
