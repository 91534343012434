import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PSPage from 'components/globals/ui/layout/Page';
import LoginForm from "components/globals/user/LoginForm"

export default function Login() {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const success = searchParams.get('success');

  useEffect(() => {
    if (userInfo) {
      navigate('/home');
    }
  }, [navigate, userInfo]);

  return (
    <>
      <PSPage>
        <div className="max-w-[600px] w-main mt-lg">
          
          {success === 'true' && (
            <>
              <div className="flex items-center bg-green py-3 pl-5 pr-1 text-black block my-sm">
                <p>Successful. You can login.</p>
              </div>
            </>
          )}

          <h1>Login</h1>
          <LoginForm />
        </div>
      </PSPage>
    </>
  );
}
