import { React } from "react";
import { useAuth } from 'providers/AuthContext';

export default function IsYoursBadge({comparison}) {
	const { userInfo, isFetching } = useAuth();

	return (
		<>
			{ !isFetching && comparison ===  userInfo?.user_id && (
				<div
					className="
						py-[2px] 
						px-2 
						bg-green 
						text-black 
						rounded-md 
						inline-block
						text-[10px]
						font-bold
					"
				>
					YOURS
				</div>
			)}				
		</>
  );
}