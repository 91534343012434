import { React } from "react";

const ORSMemberNumber = ({ number }) => {

  return (
    <div>

      <div className="my-2 rounded-lg border-2 border-black overflow-hidden inline-block text-center w-[130px]">
        <div className="bg-black py-2 px-3 text-white font-bold text-[12px]">MEMBER</div>
        <div className="bg-secondary text-black pt-2 pb-3 px-3 text-4xl font-bold">{number}</div>
      </div>

    </div>
  );
};

export default ORSMemberNumber;
