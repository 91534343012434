import { React } from "react"
import PSPage from "components/globals/ui/layout/Page"
import { NavLink } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";

const Support = () => {

	return (
		<>
			<PSPage>
				<h1 className="text-3xl font-bold mb-small mt-md">Support</h1>

				<p className="my-md">Get in touch with us.</p>

				<NavLink className="button" to="mailto:jessicaaebersold@gmail.com">
					<Button variant="outline">Email Us</Button>
				</NavLink>

				<div className="my-sm w-full aspect-[16/9] relative">
					<iframe
						className="absolute inset-0 w-full h-full"
						src="https://www.youtube.com/embed/7fQ-xuUrbsM?si=lKvlTjjiw9bt3jTJ" 
						title="YouTube video player" 
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
						referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
				</div>
				
				
			</PSPage>
		</>
		
	);
};

export default Support;
