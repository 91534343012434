import { React } from "react";
import Single from "components/globals/image/Single";
import clsx from "clsx";

const ORSDetailPageMarquee = ({ gallery, banners, children, staticImages = false }) => {
  const hasBanners = Array.isArray(banners) && banners.length > 0;

  return (
    <div className={clsx(
      "relative",
      hasBanners ? "mb-sm" : "my-md",
    )}>

      {hasBanners && (
        <Single
          banner
          className="
            banner-image 
            block 
            relative 
            w-full 
            overflow-hidden 
            md:max-h-[350px] 
            overflow-hidden
          "
          natural
          image={banners[0]}
          staticImage={staticImages}
        />
      )}

      <div className="w-main md:flex md:items-start">
        
        <div className={clsx(
            " shrink-0 w-[150px] md:w-[300px] relative",
            hasBanners ? "-mt-[70px] md:mt-[200px]" : "md:mt-0",
          )}>
          <Single
            className={clsx(
              "aspect-[4/3.5] w-full  rounded-default overflow-hidden shadow-[0_0_15px_10px_rgba(0,0,0,0.1)]",
              hasBanners ? "md:absolute bottom-0" : "relative",
            )}
            image={Array.isArray(gallery) && gallery.length > 0 ? gallery[0] : null}
            staticImage={staticImages}
          />
        </div>

        <div className={clsx(
          "grow py-xs  relative",
          hasBanners ? "md:p-sm" : "md:p-sm md:pt-0",
        )}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ORSDetailPageMarquee;
