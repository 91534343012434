import React from "react";
import clsx from "clsx";

export default function PSRows({ children, mobileCarousel = false, rowColumns = false }) {
  return (
    <div
      className={clsx(
        "relative",
        rowColumns && "md:grid md:grid-cols-2 gap-3"
      )}
    >
      {children}
    </div>
  );
}
