import { React, useEffect, useState, useRef, memo } from "react";
import NoResults from "components/globals/NoResults";
import PSGrid from "components/globals/ui/Grid"
import PSRows from "components/globals/ui/Rows"
import Card from "components/globals/ui/Card";
import Row from "components/globals/ui/Row";
import { useFetchData } from "helpers/utils";
import { Button } from "components/globals/ui/input/Button";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { useGetUserEntriesQuery } from "services/auth/authService";

const MemoizedCard = memo(Card);
const MemoizedRow = memo(Row);

export function ORSMasterListing({params, displayOptions}) {
  return (
    <>
      <ListingHeader
        title={params.headingTitle || null}
        link={params.headingButtonUrl || null}
        linkText={params.headingButtonText || null}
        createButton={params.createButton || null}
        createButtonPath={params.createButtonPath || null}
      />
      <Listing
        apiPath={params.apiPath}
        omit={params.omit}
        limit={params.limit}
        type={params.type}
        pagination={params.pagination}
        displayOptions={displayOptions}
        protectedQuery={params.protected || false}
      />
    </>
  )
}

const ListingHeader = ({title, link, linkText, createButton, createButtonPath}) => {
  return (
    <>
      <div className="flex items-start mb-sm mt-md">
        <h3 className="text-3xl font-bold grow">{title}</h3>

        {link && (
          <NavLink to={link}>
            <Button variant="outline">{linkText}</Button>
          </NavLink>
        )}

        {createButton && (
          <NavLink className="ml-3" to={createButtonPath}>
            <Button variant="primary">
              <FaPlus />
              <span className="inline-block ml-2">ADD NEW</span>
            </Button>
          </NavLink>
        )}

      </div>
    </>
  );
};

const Listing = ({
  apiPath,
  omit,
  type,
  limit,
  pagination,
  protectedQuery,
  displayOptions,
}) => {
  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);
  const newItemsRef = useRef([]);

  const url = `${process.env.REACT_APP_SERVER_URI}/api/${apiPath}/${page}/${omit}/${limit}`;
  
  const {
    data: protectedData,
    error: protectedError,
    loading: protectedLoading,
  } = useGetUserEntriesQuery({ page, limit, url });

  const {
    data: publicData,
    error: publicError,
    loading: publicLoading,
  } = useFetchData(url, [page]);

  const data = protectedQuery ? protectedData : publicData;
  const error = protectedQuery ? protectedError : publicError;
  const loading = protectedQuery ? protectedLoading : publicLoading;

  useEffect(() => {
    if (data?.entries) {
      const newEntries = data.entries.filter(
        (newItem) => !items.some(
          (existingItem) => existingItem.internal_id === newItem.internal_id
        )
      );

      // Store references to newly added items
      newItemsRef.current = newEntries;

      // Append new items to existing list
      setItems((prevItems) => [...prevItems, ...newEntries]);
    }
  }, [data]);

  useEffect(() => {
    if (newItemsRef.current.length > 0) {
      // Find the first new item and scroll to it
      const firstNewItemId = `item-${newItemsRef.current[0].internal_id}`;
      const firstNewItem = document.getElementById(firstNewItemId);
      
      if (firstNewItem) {
        firstNewItem.scrollIntoView({ 
          behavior: "smooth", 
          block: "nearest" 
        });
      }

      // Reset the new items reference
      newItemsRef.current = [];
    }
  }, [items]);

  const loadMoreItems = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // Loading & error states
  if (loading && page === 0) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      {displayOptions.layout === "row" ? (
        <PSRows mobileCarousel={displayOptions.mobileCarousel} rowColumns={displayOptions.rowColumns}>
          {items && items.length > 0 ? (
            <>
              {items.map((entry) => (
                <MemoizedRow
                  key={entry.internal_id || entry.user_id}
                  item={entry}
                  path={type}
                  displayOptions={displayOptions}
                  id={`item-${entry.internal_id || entry.user_id}`} // Assign unique ID
                />
              ))}
            </>
          ) : (
            <NoResults label="No items Found" />
          )}
        </PSRows>
      ) : (
        <>
          {items && items.length > 0 ? (
            <PSGrid mobileCarousel={displayOptions.mobileCarousel}>
              {items.map((entry, index) => (
                <MemoizedCard
                  key={`${entry.internal_id || entry.user_id}-${index}`}
                  item={entry}
                  path={type}
                  displayOptions={displayOptions}
                  id={`item-${entry.internal_id || entry.user_id}`} 
                />
              ))}
            </PSGrid>
          ) : (
            <NoResults label="No items Found" />
          )}
        </>
      )}

      {items?.length < data?.total && pagination && (
        <div className="text-center mb-md mt-lg">
          <Button 
            variant="primary" 
            onClick={loadMoreItems}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Load More'}
          </Button>
        </div>
      )}
    </>
  );
};