import React, { useEffect, useState } from "react";
import fallbackImage from "assets/images/default.jpg";
import baseImage from "assets/images/base-template.jpg";

/*

sizes: 

default: 600x500
medium: 400x300
small: 100x100
profile: 27x27

*/

const Single = ({ image, className, bucket, natural = false, staticImage = false, banner = false }) => {
  const [img, setImg] = useState(fallbackImage);

  useEffect(() => {
    setImg(image);
  }, [image]);

  return (
    <div className={className}>
      {img ? (
        <>
          {natural ? (
            <img
              className='block w-full'
              src={staticImage ? img.filename : `//ps-images.imgix.net/${img.filename}?q=70&width=${banner ? '1440' : '600'}`}
              alt="baseImage"
            />
          ) : (
            <div
              className="bg-cover bg-center absolute inset-0"
              style={staticImage ? {
                backgroundImage: `url(${img.filename})`
              } : {
                backgroundImage: img.filename ? `url(//ps-images.imgix.net/${img.filename}?q=70&width=${banner ? '1440' : '600'})` : `url(${baseImage}`,
              }}
            >
              <img
                src={baseImage}
                alt="baseImage"
                className="opacity-0 block"
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{ backgroundImage: `url(${baseImage})` }}
            className="absolute inset-0 bg-cover scale-[1.1]"
          >
            <img src={baseImage} alt="baseImage" className="opacity-0 block" />
          </div>
        </>
      )}
    </div>
  );
};

export default Single;
