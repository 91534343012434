import React from 'react';
import { useAuth } from 'providers/AuthContext';
import { DashboardTemplate } from './Dashboard';
import {ORSMasterListing} from "components/globals/ORSMasterListing"

export default function Articles() {

	const { userInfo, isFetching } = useAuth();

	const sections = [
		{ 
			params: {
				elementId: "allArticles", 
				headingTitle: "Admin: Articles",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: "article",
				omit: "none",
				limit: "24",
				type: "article", 
				pagination: true,
				createButton: true,
				createButtonPath: "/new/article"
			},
			displayOptions: {
				layout: "row",
				yoursBadge: true,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: false,
				mobileCarousel: false,
				likes: true,
			}
		}
	]

	return (
		<>
			{!isFetching && userInfo ? (
				<DashboardTemplate userInfo={userInfo} title="Articles">

					{sections.map(({params, displayOptions}) => (
						<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
					))}
					
				</DashboardTemplate>
			) : null }
		</>
	);
}