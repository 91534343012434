import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";
import {ORSMasterListing} from "components/globals/ORSMasterListing"

import marquee from "assets/images/marquees/cars.jpg";
import marqueeMobile from "assets/images/marquees/cars-mobile.jpg";


export default function Cars() {

  const sections = [
    { 
      params: {
				elementId: "cars", 
				headingTitle: "Recent Spots",
				headingButtonUrl: null,
				headingButtonText: null,
				apiPath: "post/type/spot",
				omit: "none",
				limit: "24",
				type: "post", 
				pagination: false,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    }
  ]


  return (
    <>
      <ORSSubnav>
        <SubnavItem to="/cars" label="Cars Home" />
        <SubnavItem to="/cars/brands" label="Brands" />
        <SubnavItem to="/cars/users" label="User Cars" />
				<SubnavItem active to="/cars/spotted" label="Spotted" />
      </ORSSubnav>

      <ORSBreadcrumbs>
        <BreadcrumbLink to="/" label="Home" />
        <BreadcrumbSpacer />
				<BreadcrumbLink to="/cars" label="Cars" />
        <BreadcrumbSpacer />
        <BreadcrumbSpan label="Spotted" />
      </ORSBreadcrumbs>

      <PSPage>
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
};
