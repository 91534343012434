import React from "react";
import { Link } from 'react-router-dom';
import { useAuth } from 'providers/AuthContext';
import { FaExternalLinkAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "store/authSlice";

export default function PSPage({
  children, 
  header, 
  publicProfileLink, 
  logoutLink}) {
  return (
    <>
      <div className="relative pb-[200px]">
        {header && (
          <PSPageHeader 
            title={header} 
            publicProfileLink={publicProfileLink} 
            logoutLink={logoutLink} />
        )}

        <div className="w-main">
          {children}
        </div>
      </div>
    </>
  );
};

export function PSPageHeader({ title, publicProfileLink, logoutLink }) {
  const { userInfo, isFetching } = useAuth();
  const dispatch = useDispatch();

  const handlelogout = function () {
    dispatch(logout());
  };
  
  return (
    <div className="w-full bg-secondary mb-6">
      <div className="w-main flex items-center py-2">
        <h1 
          className="
            font-bold 
            tracking-[.5px] 
            text-[17px] 
            uppercase 
            grow
          "
        >{title}</h1>
        
        { !isFetching && publicProfileLink ? (
          <Link className="cursor-pointer flex items-center shrink-0" to={`/users/${userInfo.username}`}>
            <span className="block text-[13px] tracking-[.5px]">Public Profile</span>
            <span className="text-[12px] block ml-1">
              <FaExternalLinkAlt />
            </span>
          </Link>
        ) : null }

        { !isFetching && logoutLink ? (
          <span className="inline-block text-red font-bold text-[12px] ml-5" onClick={handlelogout}>
            LOGOUT
          </span>
        ) : null }
      </div>
    </div>
  );
}