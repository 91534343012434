
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PSInputBase, PSSubmit } from "components/globals/ui/input/PSInputs";
import { createEntry, deleteEntry, updateEntry } from "store/authSlice";
import { createFormData } from "helpers/utils";
import NoResults from "components/globals/NoResults";
import User from "components/globals/user/badge";
import PSRowDate from "components/globals/ui/row/Date";
import { Schema } from "types/commentTypes";
import { useAuth } from "providers/AuthContext";
import { FaRegTrashAlt } from "react-icons/fa";


export default function Comments({ entry_id, entry_type }) {
  const userToken = useSelector((state) => state.auth.userToken);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const [postData, updatePostData] = useState(Schema);
  const [editingComment, setEditingComment] = useState(null);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn, shallowEqual);
  const { userInfo } = useAuth();


  const fetchComments = useCallback(async (resetComments = false) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URI}/api/comment/${entry_type}/${entry_id}/${page}/none/10`;
      const response = await fetch(url);
      const data = await response.json();
      
      if (data?.entries) {
        setComments(prevComments => 
          resetComments ? data.entries : [...prevComments, ...data.entries]
        );
        setTotal(data?.total || 0);
      }
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  }, [entry_type, entry_id, page]);

  // Initial comments fetch
  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const onInputChange = (context, val) => {
    updatePostData(prevData => ({
      ...prevData,
      body: val,
      document_type: entry_type,
      document_id: entry_id
    }));
  };

  const submitForm = async () => {
    if (!userToken) return;

    if (!postData.body || postData.body.length <= 0) {
      alert("Comment required");
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    const fd = createFormData(postData, Schema);

    if(editingComment) {
      fd.append("internal_id", editingComment.internal_id);
    }

    try {
      if (editingComment) {
        // Update existing comment
        const action = updateEntry({ 
          data: fd, 
          entry_type: "comment", 
          id: editingComment.internal_id 
        });
        await dispatch(action).unwrap();
        setEditingComment(null);
      } else {
        // Create new comment
        const action = createEntry({ data: fd, entry_type: "comment" });
        await dispatch(action).unwrap();
      }
      
      // Reset input and fetch updated comments
      updatePostData(Schema);
      await fetchComments(true); // Reset comments and fetch latest
    } catch (err) {
      setErrorMessage(err.message || "Failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (!window.confirm("Are you sure you want to delete this comment?")) return;

    try {
      const action = deleteEntry({ 
        internal_id: commentId,
        entry_type: "comment"
      });
      await dispatch(action).unwrap();
      
      // Fetch updated comments
      await fetchComments(true);
    } catch (err) {
      setErrorMessage(err.message || "Failed to delete comment.");
    }
  };

  const loadMoreComments = async () => {
    setLoadingMore(true);
    try {
      setPage(prevPage => prevPage + 1);
      await fetchComments();
    } catch (error) {
      console.error("Failed to load more comments:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleEditComment = (comment) => {
    setEditingComment(comment);
    updatePostData(prevData => ({
      ...prevData,
      body: comment.body
    }));
  };

  const cancelEdit = () => {
    setEditingComment(null);
    updatePostData(Schema);
  };

  return (
    <>
      {isLoggedIn && (
        <div className="my-sm">
          {comments.length > 0 ? (
            <>
              {comments.map((entry) => (
                <div key={entry.internal_id} className="p-3 mb-2 bg-[#ffffff] rounded-lg relative">
                  <div className="flex items-center mb-2">

                    <div className="mr-2">
                      <User userId={entry.user_id} />
                    </div>
                    <div className="relative -mt-1">
                      <PSRowDate date={entry.created_at} />
                    </div>
                    
                    {entry.user_id === userInfo.user_id && (
                      <div className="ml-auto flex space-x-2">
                        <button 
                          onClick={() => handleEditComment(entry)}
                          className="text-blue-500 text-sm"
                        >
                          Edit
                        </button>
                        <button 
                          onClick={() => handleDeleteComment(entry.internal_id)}
                          className="text-red-500 text-sm"
                        >
                          <FaRegTrashAlt />
                        </button>
                      </div>
                    )}
                  </div>
                  {entry.user_id === userInfo.user_id && (
                    <>
                      <span className="bg-green py-[2px] px-[4px] text-black font-bold rounded-md inline-block text-[11px]">Your Comment</span>
                    </>
                  )}
                  <p className="text-[13px] mt-2">{entry.body}</p>
                </div>
              ))}
            </>
          ) : (
            <NoResults label="No comments found" />
          )}

          {total > comments.length && (
            <div className="text-center mb-md mt-lg">
              <button
                onClick={loadMoreComments}
                className="btn btn-primary"
                disabled={loadingMore}
              >
                {loadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          )}

          <div>
            <PSInputBase
              name="comment"
              label={editingComment ? "Edit Comment" : "Add New Comment"}
              type="text"
              context="body"
              required
              value={postData.body || ""}
              onChange={onInputChange}
            />
            
            <div className="flex space-x-2">
              <PSSubmit
                onClick={submitForm}
                loading={loading}
                label={loading 
                  ? (editingComment ? "Updating..." : "Posting...") 
                  : (editingComment ? "Update Comment" : "Post Comment")
                }
                disabled={loading}
              />
              
              {editingComment && (
                <button 
                  onClick={cancelEdit}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}