import { React, useEffect, useState } from "react";
import PSPage from "components/globals/ui/layout/Page"
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { updateUserPassword } from 'store/authSlice'
import PSInputText from "components/globals/ui/input/PSInputText";
import { PSSubmit } from "components/globals/ui/input/PSInputs";
import { prepareFormData, useFetchData } from "helpers/utils";
import { StandardErrorMessage } from "components/globals/messages/StandardMessages";
import { Button } from "components/globals/ui/input/Button";

export default function ResetPassword() {
	const { loading, userInfo, error, success } = useSelector(
		(state) => state.auth
	)
	const dispatch = useDispatch()
	const form = useForm({
		mode: 'onBlur'
	});
	const { handleSubmit, reset, register, setError, formState, setValue, clearErrors } = form;
	const { errors } = formState;
	const navigate = useNavigate();
	const { userid } = useParams(); // ex: 00f58051f05e499ba51b4a0d2241db4d

	const { data: user } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/users/publicUserById/${userid}`,
		[userid],
	);

	useEffect(() => {
		form.setValue('userid', userid);
	}, [userid]);

	useEffect(() => {
		if (success) navigate('/login?success=true')
		if (userInfo) navigate('/dashboard/overview')  
	}, [navigate, userInfo, success])

	const submitForm = async (data) => {
		// Check if passwords match
		if (data.password !== data.confirmPassword) {
			setError('confirmPassword', {
				type: 'manual',
				message: 'Passwords do not match'
			});
			return;
		}

		if (data.password.length < 5) {
			setError('confirmPassword', {
				type: 'manual',
				message: 'Passwords must be at least 5 characters'
			});
			return;
		}
		
		const formData = prepareFormData(data); 
		
		try {
			const response = await dispatch(updateUserPassword(formData)).unwrap();
			if (response.success === true) {
				navigate('/login?success=true');
			}
		} catch (error) {
			setError("server", {
				type: "manual",
				message: error.message || "Failed to update password"
			});
		}
	};

	// Determine if submit button should be disabled
	const isSubmitDisabled = false;

	return (
		<>
			<PSPage>
			
				{!user?.OneTimePasswordReset && (
					<div className="max-w-[600px] w-main mt-lg">
						<FormProvider {...form}>
							<form onSubmit={handleSubmit(submitForm)}>
	
								<PSInputText
									register={form.register}
									name="password"
									label="Password"
									type="password"
									required
								/>

								<PSInputText
									register={form.register}
									name="confirmPassword"
									label="Confirm Password"
									type="password"
									error={errors.confirmPassword?.message}
									required
								/>

								<PSSubmit 
									loading={loading} 
									label='Update Password' 
									disabled={isSubmitDisabled}
								/>

								{error && <StandardErrorMessage msg={error} />}

							</form>
						</FormProvider>
					</div>
				)}
			</PSPage>
		</>
	);
};