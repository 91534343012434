import { React } from "react";
import { Link } from "react-router-dom";
import Single from "components/globals/image/Single";
import UserBadge from "components/globals/user/badge";
import CarBadge from "components/globals/car/badge";
import PSCardImageContainer from "components/globals/ui/card/ImageContainer";
import PSCardInfo from "components/globals/ui/card/Info";
import PSCardTitle from "components/globals/ui/card/Title";
import PSCardDate from "components/globals/ui/card/Date";
import PSCardPrice from "components/globals/ui/card/Price";
import ORSYearMakeModel from "components/globals/car/ORSYearMakeModel";
import CarDetails from "components/globals/car/CarDetails";
import IsYoursBadge from "components/globals/IsYoursBadge";
import { useSelector, shallowEqual } from "react-redux";
import EntryUserActions from "components/globals/ui/card/EntryUserActions";
import { usePostPane } from '../../../providers/PostPaneContext';
import Likes from "components/globals/likes/Likes";

export default function PSCard({
  item,
  price,
  path,
  displayOptions,
}) {
  const isLoggedIn = useSelector(
    (state) => state.auth.isLoggedIn,
    shallowEqual
  );

  const { openPostPane } = usePostPane();

  const triggerPostPane = () => {
    openPostPane({ item: item });
  };

  return (
    <>
      <div className="block w-full overflow-hidden" key={item.internal_id}>
        <PSCardImageContainer
          item={item}
          displayOptions={displayOptions}
        >
          <>
            <Link 
              to={item.entry_type === 'user' ? `/users/${item.username}` : `/${path}/${item.internal_id}`}
              onClick={(e) => {
                if (item.entry_type === "post") {
                  e.preventDefault();
                  triggerPostPane();
                }
              }}
            >
              <Single image={item.gallery[0]} />
            </Link>

            <div className="absolute bottom-0 left-[10px]">
              {displayOptions.yoursBadge && (
                <div className="hidden sm:block my-1">
                  <IsYoursBadge comparison={item.user_id} />
                </div>
              )}
              {displayOptions.carBadge && item.car_id && (
                <div className="hidden sm:block my-1">
                  <CarBadge car_id={item.car_id} />
                </div>
              )}
              {displayOptions.userBadge && (
                <div className="my-1">
                  <UserBadge userId={item.user_id} />
                </div>
              )}
            </div>

            <div className="absolute top-1 md:bottom-1 right-1">
              {displayOptions.likes && (
                <Likes 
                  opacity
                  entry_type={item.entry_type} 
                  entry_id={item.internal_id}
                />
              )}
            </div>

            {price && <PSCardPrice price={item.price} />}

          </>
        </PSCardImageContainer>

        <div className="flex items-start relative">
          <Link 
            className="grow" 
            to={item.entry_type === 'user' ? `/users/${item.username}` : `/${path}/${item.internal_id}`}
            onClick={(e) => {
              if (item.entry_type === "post") {
                e.preventDefault();
                triggerPostPane();
              }
            }}
          >
          <PSCardInfo>
            {item.entry_type === "user" ? (
              <>
                <h4 className="text-[20px] font-bold">{item.username}</h4>
              </>
            ) : (
             <>
              <PSCardTitle title={item.title} />

              {displayOptions.dateDetails && (
                <PSCardDate date={item.created_at} />
              )}

              {/* {eventInfo && (
                <>
                  {item.event_date ? (
                    <PSCardDate date={item.event_date} label="Event Date" />
                  ) : null}

                  {item.recurring_frequency ? (
                    <span className="block mt-1 text-[13px] text-grey">
                      {item.recurring_frequency}
                    </span>
                  ) : null}
                </>
              )} */}

              {displayOptions.carDetails && (
                <>
                  {item.car_id && !item.make && (
                    <div className="hidden sm:block">
                      <CarDetails car_id={item.car_id} />
                    </div>
                  )}
                  
                  {item.make && (
                    <div className="hidden sm:block">
                      <ORSYearMakeModel entry={{
                        year: item.year,
                        make: item.make,
                        model: item.model,
                        trim: item.trim
                      }} />
                    </div>
                  )}
                </>
              )}
             </> 
            )}
              
            </PSCardInfo>
          </Link>

          {isLoggedIn && (
            <div className="hidden md:block">
              <EntryUserActions comparison={item.user_id} item={item} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
