import React from "react";
import { formatTitle } from "helpers/utils";

export default function PSCardTitle({title}) {

	return (
		<>
			<span className="
				block
				font-bold
				text-[13px]
				sm:text-[16px]
				leading-[15px]
				sm:leading-[18px]
				"
			>{ formatTitle(title) }</span>
		</>
  );
}