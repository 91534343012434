import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";

export function ORSSubnav({children}) {
	return (
		<div className="bg-secondary pt-2 px-2 sm:px-4 border-b-[1px] border-b-[#bbb]">		
			<div className="max-w-[1200px] mx-auto">
				{children}
			</div>	
		</div>
	);
};

export function SubnavItem({to, label, active}) {
	return (
		<NavLink to={to} className="inline-block mr-2 mb-2">
			<Button variant={active ? 'outlineActive' : 'outline'}>{label}</Button>
		</NavLink>
	);
};