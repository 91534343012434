import React from "react";
import { NavLink } from "react-router-dom";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import cover from "assets/images/footer.jpg";
import logo from "assets/images/white-logo.png";
import ORSVersion from "components/globals/ORSVersion";


const FooterColumn = function({title, children}) {
  return (
    <div className="text-left w-full max-w-[400px] mx-auto md:mx-12 mb-6 md:mb-0">
      <p className="font-bold mb-2">{title}</p>
      <div className="border-t-[1px] border-white opacity-[.6] my-2"></div>
      <div className="mt-4 text-[14px] flex flex-wrap sm:block">
        {children} 
      </div>
    </div>
  )
}

const FooterLink = function({to, text}) {
  return (
    <NavLink
      className="
        bg-white/20
        sm:bg-transparent
        mx-1
        my-1
        py-1
        px-2
        sm:p-0
        sm:m-0
        sm:mb-1 
        rounded-md
        sm:block 
        sm:transition 
        sm:hover:underline
      " 
      to={to}>
      {text}
    </NavLink>
  )
} 

const Footer = () => {
  return (
    <footer className="bg-brg text-white p-md sm:p-lg bg-cover bg-center" style={{
      backgroundImage: `url(${cover})`
    }}>
      <div className="w-full text-center">
        <img src={logo} alt="The Open Road Society" className="block mx-auto w-[100px] h-auto" />
        <h3 className="text-[18px] font-bold italic mt-4">The Open Road Society</h3>

        <div className="mt-md sm:flex items-stretch justify-center">

          <FooterColumn title="The Open Road Society">
            <FooterLink to='/articles' text='Articles' />
            <FooterLink to='/cars' text='Cars' />
            <FooterLink to='/society' text='Society' />
            <FooterLink to='/marketplace' text='Marketplace' />          
          </FooterColumn>

          <FooterColumn title="About Us">
            <FooterLink to='/about' text='About Open Road Society' />
            <FooterLink to='/features' text='Site Features' />
            {/* <FooterLink to='/history' text='Our History' />           */}
          </FooterColumn>

          <FooterColumn title="Support">
            <FooterLink to='/roadmap' text='Roadmap' />
            <FooterLink to='/changelog' text='Change Log' />
            <FooterLink to='/support' text='Support' />          
          </FooterColumn>

        </div>
      </div>
      <div className="mt-lg w-full text-center">
        <a
          href="https://instagram.com/open.road.society/"
          target="blank"
          className="inline-block text-[30px] mx-auto mb-3 text-white  px-2"
        >
          <FaInstagram />
        </a>

        <a
          href="https://www.youtube.com/@open.road.society"
          target="blank"
          className="inline-block text-[30px] mx-auto mb-3 text-white px-2"
        >
          <FaYoutube />
        </a>
        <p className="text-[11px] mb-2">
          2025 Open Road Society.
        </p>
        <div className="mx-auto flex justify-center my-3">
          <ORSVersion />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
