import { React, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import PSPage from "components/globals/ui/layout/Page"
import { useFetchData, formatDate, scrollToComments } from "helpers/utils";
import parse from "html-react-parser";
import IsYoursBanner from 'components/globals/IsYoursBanner'
import ORSDetailPageMarquee from "components/globals/layout/detail/marquee";
import User from "components/globals/user/badge";
import Comments from "components/globals/comments/Comments";
import Likes from "components/globals/likes/Likes";
import { FaRegCommentAlt } from "react-icons/fa";
import { useAuth } from "providers/AuthContext";
import { Button } from "components/globals/ui/input/Button";

const Article = () => {
	const [formattedDate, setFormattedDate] = useState(0);
	const { userInfo } = useAuth();
	const { id } = useParams();

	// double entry, entry.entry & entry.user
	const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/article/detail/${id}`,
		[id],
	);

	useEffect(() => {
		setFormattedDate(formatDate(entry?.entry?.created_at));
	}, [entry]);
	

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			<ORSDetailPageMarquee 
				banners={entry.entry.banners} 
				gallery={entry.entry.gallery}
			>
        
        {/* title */}
        <h1 className="m-0 text-2xl md:text-3xl font-bold md:mb-xs">
					{entry.entry.title}
				</h1>

				<div className="flex items-center mb-3">

					<div className="mr-2">
						<Likes 
							entry_type={entry.entry.entry_type} 
							entry_id={entry.entry.internal_id}
						/>
					</div>

					<div 
						className="cursor-pointer" 
						onClick={scrollToComments}
					>
						<FaRegCommentAlt className="text-black text-2xl" />
					</div>

				</div>

        {/* owner */}
        <User 
          noPadding 
          userId={entry.entry.user_id} 
        />

        {/* Created */}
        <div className="uppercase text-[12px] tracking-[.2px]">
					<strong>{formattedDate}</strong>
				</div>

      </ORSDetailPageMarquee>

			<PSPage>

				<IsYoursBanner label="Your Article" entry={entry.entry} />

				{entry?.entry?.body && (
					<div className="mt-3 article-wysiwyg">{parse(entry?.entry?.body)}</div>
				)}

				<div id="comments" className="mx-auto max-w-[800px]">
					{userInfo ? (
						<Comments 
							entry_type={entry.entry.entry_type} 
							entry_id={entry.entry.internal_id}
						/>

					) : (
						<div className="text-center">
							<NavLink className="button" to='/login'>
								<Button variant="outline">Login to see comments</Button>
							</NavLink>
						</div>
					)}
				</div>

			</PSPage>
		</>
		
	);
};

export default Article;