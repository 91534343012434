import { React } from "react";
import fallbackImage from "assets/images/default.jpg";
import baseImage from "assets/images/base-template.jpg";
import { useDotButton } from 'components/globals/ui/carousel/EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from 'components/globals/ui/carousel/EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

const Gallery = ({ gallery, bucket }) => {

  const [emblaRef, emblaApi] = useEmblaCarousel({
		loop: true
	})

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)


	return (
		<div className="relative">
			{gallery.length > 0 ? (
				
				<div className="relative">
					{gallery.length > 1 ? (

						<div className="relative">

							<div className="embla w-full max-w-full" ref={emblaRef}>

								<div className="embla__container">
									{gallery.map((image, index) => (
										<div
											style={{ 
												backgroundImage: `url(//ps-images.imgix.net/${image.filename}?q=70&width=800)`
											}}
											className="embla__slide  bg-cover bg-center" key={index}>	
											<img src={baseImage} alt='baseImage' className="opacity-0 w-full h-auto block" />
										</div>
									))
								}
							</div>
						</div>
					
						<div className="absolute z-2 bottom-3 left-3 w-auto p-2">
							<div className="absolute inset-0 z-[1] bg-black opacity-[0.6] rounded-full"></div>
							<div className="relative z-[2] flex items-center">
								<div className="flex items-center shrink-0">
									<PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
									<NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
								</div>
							</div>
							
						</div>

					</div>

					) : (

						<>
							{gallery[0].filename ? (
								<>
								<div 
									style={{ 
										backgroundImage: `url(//ps-images.imgix.net/${gallery[0].filename}?q=70&width=800)`
									}}
									className="bg-cover bg-center w-full">	
									<img src={baseImage} alt='baseImage' className="opacity-0 block w-full h-auto" />
								</div>
								</>
							): (
								<>
								<div 
									className="bg-cover bg-center w-full">	
									<img src={baseImage} alt='baseImage' className="opacity-1 block w-full h-auto" />
								</div>
								</>
							)}
						</>
						
					)}
				</div>
			) : (
				<div 
					style={{ backgroundImage: `url(${fallbackImage})` }} 
					className="bg-cover bg-center w-[300px] h-[200px]">	
					<img src={baseImage} alt='baseImage' className="opacity-0 block" />
				</div>
			)}
		</div>
	);
};

export default Gallery;
