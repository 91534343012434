import React from "react";
import PSPage from "components/globals/ui/layout/Page"
import {ORSMasterListing} from "components/globals/ORSMasterListing"

const sections = [
  { 
    params: {
      elementId: "newPosts", 
      headingTitle: "All User Posts",
      shortTitle: "Posts",
      headingButtonUrl: null,
      headingButtonText: null,
      apiPath: "post/type/all",
      omit: "none",
      limit: "48",
      type: "post", 
      pagination: true,
    },
    displayOptions: {
      layout: "card",
      yoursBadge: true,
      userBadge: true,
      typeBadge: true,
      carBadge: true,
      carDetails: true,
      dateDetails: true,
      rowColumns: true,
      mobileCarousel: false,
      likes: true,
    }
  },
  
];

export default function Posts() {
  return (
    <>
      <PSPage>
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
}