import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import { Button } from "components/globals/ui/input/Button";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { DashboardSidebar } from "./Sidebar";
import { FaUserFriends } from "react-icons/fa";

export const DashboardTemplate = function ({ title, userInfo, children }) {

  return (
    <PSPage
      header={title} publicProfileLink logoutLink>
      <div className="md:flex md:items-start">
        <DashboardSidebar userInfo={userInfo} />
        <div className="grow">
          <div className="py-2">
            <NavLink to="/new/car" className="inline-block mr-2 mb-2">
              <Button variant="primary">
                <FaPlus />
                <span className="inline-block ml-2">New Garage Car</span>
              </Button>
            </NavLink>
            <NavLink to="/new/project" className="inline-block mr-2 mb-2">
              <Button variant="primary">
              <FaPlus />
                <span className="inline-block ml-2">New Project</span>
              </Button>
            </NavLink>
            {/* <NavLink to="/new/group" className="inline-block mr-2 mb-2">
              <Button variant="primary">
                <FaPlus />
                <span className="inline-block ml-2">New Group</span>
              </Button>
            </NavLink> */}
            <NavLink to="/new/event" className="inline-block mr-2 mb-2">
              <Button variant="primary">
                <FaPlus />
                <span className="inline-block ml-2">New Event</span>
              </Button>
            </NavLink>
          </div>

          <hr className="block my-1 h-[1px] border-top border-border" />

          <div className="pt-2 flex items-center">
            <NavLink to="/dashboard/followers" className="inline-block mr-2 mb-2">
              <Button variant="dark">
                <FaUserFriends />
                <span className="inline-block ml-2">Followers</span>
              </Button>
            </NavLink>

            <NavLink to="/dashboard/following" className="inline-block mr-2 mb-2">
              <Button variant="dark">
                <FaUserFriends />
                <span className="inline-block ml-2">Following</span>
              </Button>
            </NavLink>

          </div>

          <hr className="block my-1 h-[1px] border-top border-border" />

          {userInfo.accountType === "admin" && (
            <div className="my-4 p-3 bg-secondary">
              <h2 className="text-2xl font-bold mb-2">Admin Functions</h2>
              <NavLink to="/new/article" className="inline-block mr-2 mb-2">
                <Button variant="secondary">
                  <FaPlus />
                  <span className="inline-block ml-2">New Article</span>
                </Button>
              </NavLink>
              <NavLink to="/dashboard/articles" className="inline-block mr-2 mb-2">
                <Button variant="secondary">
                  <span className="inline-block">Articles</span>
                </Button>
              </NavLink>
            </div>
           )}

          {children}
        </div>
      </div>
      
    </PSPage>
  );
};
