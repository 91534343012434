import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PSPage from "components/globals/ui/layout/Page"
import { formatDate, useFetchAuthedData, useFetchData } from "helpers/utils";
import parse from "html-react-parser";
import IsYoursBanner from 'components/globals/IsYoursBanner'
import CarBadge from "components/globals/car/badge";
import Gallery from "components/globals/image/Gallery";
import Comments from "components/globals/comments/Comments";
import UserBadge from "components/globals/user/badge";
import Likes from "components/globals/likes/Likes";
import TypeBadge from "components/globals/ui/TypeBadge";
import {ORSMasterListing} from "components/globals/ORSMasterListing"


const EventPage = () => {
	const [formattedDate, setFormattedDate] = useState(0);
	const { id } = useParams();

	// double entry, entry.entry & entry.user
	const { data: entry, loading, error } = useFetchAuthedData(
		`event/${id}`,
		[id],
	);

	// const { data: user } = useFetchData(
	// 	`${process.env.REACT_APP_SERVER_URI}/api/users/publicUserById/${entry?.entry?.user_id}`,
	// 	[entry],
	// );

	const sections = [
		{
			params: {
				elementId: "userFeed", 
				headingTitle: "Other Events",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/event`,
				omit: entry?.entry?.internal_id,
				limit: "12",
				type: "event", 
				pagination: true,
			},
			displayOptions: {
				layout: "card",
				yoursBadge: false,
				typeBadge: true,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: true,
				mobileCarousel: false,
				likes: true,
			}
		}
	]

	useEffect(() => {
		setFormattedDate(formatDate(entry?.entry?.created_at));
	}, [entry]);

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			<PSPage>

				<IsYoursBanner label="Your Event" entry={entry.entry} />

				<div className="flex items-start flex-wrap md:flex-nowrap gap-sm">

					<div className="md:sticky md:top-[100px] w-full md:w-1/2 rounded-default overflow-hidden">
						<Gallery gallery={entry.entry.gallery} />
					</div>

					<div className="w-full md:w-1/2 mt-xs md:mt-0">
						
						<div className="my-1 inline-block">
							<TypeBadge entryType={entry.entry.entry_type} type={entry.entry.type} category={entry.entry.category} />
						</div>
						
						<h1 className="text-3xl font-bold">{ entry.entry.title }</h1>
	
						<div>
							<Likes 
								entry_type={entry.entry.entry_type} 
								entry_id={entry.entry.internal_id}
							/>
						</div>
						<p>{formattedDate}</p>

						{entry?.entry?.body && (
							<div className="mt-xs mb-md ors-rich-text">{parse(entry?.entry?.body)}</div>
						)};

						<div className="flex items-stretch md:flex-nowrap flex-wrap gap-3">
							{entry.entry.car_id && (
								<div className="p-2 bg-secondary w-full md:w-1/2">
									<p className="font-bold text-[12px] mb-2">FOR CAR:</p>
									<CarBadge car_id={entry.entry.car_id} />
								</div>
							)}

							{entry.entry.user_id && (
								<div className="p-2 bg-secondary w-full md:w-1/2">
									<p className="font-bold text-[12px] mb-2">POSTED BY:</p>
									<UserBadge userId={entry.entry.user_id} />
								</div>
							)}

							{/* {entry.entry.group_id && (
								<div className="p-2 bg-secondary w-full md:w-1/2">
									<p className="font-bold text-[12px] mb-2">GROUP:</p>
									<p>{entry.entry.group_id}</p>
								</div>
							)}

							{entry.entry.event_id && (
								<div className="p-2 bg-secondary w-full md:w-1/2">
									<p className="font-bold text-[12px] mb-2">EVENT:</p>
									<p>{entry.entry.event_id}</p>
								</div>
							)}

							{entry.entry.project_id && (
								<div className="p-2 bg-secondary w-full md:w-1/2">
									<p className="font-bold text-[12px] mb-2">PROJECT:</p>
									<p>{entry.entry.project_id}</p>
								</div>
							)} */}
						</div>

						<Comments 
							entry_type={entry.entry.entry_type} 
							entry_id={entry.entry.internal_id}
						/>


					</div>

				</div>


				{sections.map(({params, displayOptions}) => (
					<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
				))}


			</PSPage>
		</>
		
	);
};

export default EventPage;
