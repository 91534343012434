export const Schema = {
  gallery: "",
  banners: "",
  title: "",
  body: "",
  type: "",
  category: "",
  group_id: "",
  event_id: "",
	car_id: "",
};

export const associationTypes = ["garage", "projects", "events", "groups"];

export const idMap = {
  groups: "group",
  events: "event",
	projects: "project",
	garage: "car",
};


export const associationState = {
  garage: "",
  projects: "",
  events: "",
  groups: "",
};

export const types = [
  {
    key: "news",
    label: "News",
  },
  {
    key: "profile",
    label: "Profile",
  },
  {
    key: "opinion",
    label: "Opinion/Editorial",
  },
	{
    key: "other",
    label: "Other",
  },
];

export const categories = [
  {
    type: "news",
    items: [
      {
        key: "siteUpdates",
        label: "Site Updates",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },
  {
    type: "profile",
    items: [
      {
        key: "user",
        label: "User Profile",
      },
      {
        key: "car",
        label: "Car Profile",
      },
    ],
  },
  {
    type: "opinion",
    items: [
      {
        key: "hotTake",
        label: "Hot Take",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },

  {
    type: "other",
    items: [
      {
        key: "other",
        label: "Other",
      },
    ],
  },
];