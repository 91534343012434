// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { logout } from 'store/authSlice';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URI,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)  
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({
        url: 'api/users/loggedInUser',
        method: 'GET',
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          // If there's an error, dispatch logout action
          // localStorage.removeItem('userToken');
          // dispatch(logout());
        }
      },
    }),

    getUserEntries: builder.query({
      query: ({ page = 1, limit = 5, url }) => ({
        url: url,
        method: 'GET',
        params: { page, limit }
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log('there was an error');
        }
      },
    }),

    getUserEntry: builder.query({
      query: (url) => ({
        url: `/api/protected/${url}`,
        method: 'GET',
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.log('there was an error in getUserEntry');
        }
      },
    }),

  }),
})


export const { 
  useGetUserDetailsQuery,
  useGetUserEntriesQuery,
  useGetUserEntryQuery } = authApi
