import React from 'react';
import { NavLink } from 'react-router-dom';

export const DashboardSidebar = function() {
  return (

    <div className="
      md:w-[200px]
      md:mr-12 
      rounded-small 
      shrink-0 
      flex
      justify-between
      md:justify-start
      md:flex-col
      md:sticky
      md:flex-wrap
      my-3
      md:my-0
      top-[70px] 
      bg-secondary 
      border-[1px]
      border-brg
      font-bold
      uppercase
      text-[13px]
      overflow-hidden">

      <NavLink 
        to="/dashboard/overview"
        className={({ isActive }) =>
          isActive
            ? "w-1/3 md:w-full shrink-0 flex items-center md:justify-start justify-center block text-white tive bg-brg px-3 py-3"
            : "w-1/3 md:w-full shrink-0 flex items-center md:justify-start justify-center block text-brg px-3 py-3"
        }>
        My Content
      </NavLink>
      <NavLink 
        to="/dashboard/garage"
        className={({ isActive }) =>
          isActive
            ? "w-1/3 md:w-full shrink-0 flex items-center md:justify-start justify-center block text-white tive bg-brg px-3 py-3"
            : "w-1/3 md:w-full shrink-0 flex items-center md:justify-start justify-center block text-brg px-3 py-3"
        }>
        Garage
      </NavLink>
      <NavLink 
        to="/dashboard/settings"
        className={({ isActive }) =>
          isActive
            ? "w-1/3 md:w-full shrink-0 flex items-center md:justify-start justify-center block text-white tive bg-brg px-3 py-3"
            : "w-1/3 md:w-full shrink-0 flex items-center md:justify-start justify-center block text-brg px-3 py-3"
        }>
        Settings
      </NavLink>

    </div>
    

  );
}