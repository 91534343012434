import { React } from "react"
import PSPage from "components/globals/ui/layout/Page"

const History = () => {

	return (
		<>
			<PSPage>
				<h1 className="text-3xl font-bold mb-small mt-md">Our History</h1>

				<p>I've been doing this stuff since I was a kid. Here's some fun photos and stuff.</p>
				
			</PSPage>
		</>
		
	);
};

export default History;
