import React, { createContext, useContext, useState } from 'react';

const PostPaneContext = createContext();

export const usePostPane = () => useContext(PostPaneContext);

export const PostPaneProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [postPaneData, setPostPaneData] = useState({});

  const openPostPane = (data) => {
    setPostPaneData(data);
    setIsOpen(true);
  };

  const closePostPane = () => setIsOpen(false);

  return (
    <PostPaneContext.Provider value={{ isOpen, postPaneData, openPostPane, closePostPane }}>
      {children}
    </PostPaneContext.Provider>
  );
};