import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Single from "components/globals/image/Single";
import User from "components/globals/user/badge";
// import Listing from "components/globals/Listing";
import parse from "html-react-parser";
import PSPage from "components/globals/ui/layout/Page"
import GroupListingCount from "components/globals/group/listings/Count"
import GroupWantCount from "components/globals/group/wants/Count"
import PSPageSegment from "components/globals/ui/layout/PageSegment"
import { formatDate } from "helpers/utils";
import { useFetchData } from "helpers/utils";
import Comments from "components/globals/comments/Comments";

const Group = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { id } = useParams();

	const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/groups/group/${id}`,
		[id],
	);

  useEffect(() => {
    setFormattedDate(formatDate(entry?.entry?.created_at));
  }, [entry]);

	const handleTotal = (total) => {
    // console.log('total', total);
  };

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  return (
		<>
			<PSPage>

				<div className="relative">
					
					{/* BANNER */}
					<div className="relative aspect-[16/6] w-full overflow-hidden">
						{entry.entry.banner_image.length > 0 ? (
							<Single image={entry.entry.banner_image} />
						) : (
							<Single />
						)}

						<div className="expand z-[2] bg-gradient-to-b from-black/0 to-black/50"></div>
					</div>
			
					{/* PROFILE */}
					<div className="relative aspect-square w-[200px] -mt-[100px] rounded-default overflow-hidden z-[3] ml-[20px]">
						{entry.entry.profile.length > 0 ? (
							<Single image={entry.entry.profile} />
						) : (
							<Single />
						)}
					</div>

					<h1 className="relative -top-[80px] ml-[250px]">{ entry.entry.title }</h1>

				</div>

				<div className="px-[20px]">
					<h2>Details</h2>
					<div className="flex items-center mb-3">
						<p className="mr-1 bold">Group Owner:</p>
						<User userId={entry.entry.user_id} />
					</div>

					<p className="mb-3">Established: <span className="font-bold">{formattedDate}</span></p>

					{entry?.entry?.description && (
						<div>{parse(entry?.entry?.description)}</div>
					)}

					<p>Region: { entry.entry.region }</p>

					{/* STATS summary */}
					<div className="border border-grey my-4 p-4">
						<p>Stats</p>
						<ul>
							<li>Group Members: { entry.entry.members.length + 1 }</li>
							<li>Group Listings: <GroupListingCount groupId={entry.entry.internal_id} /> </li>
							<li>Group Want-Ads: <GroupWantCount groupId={entry.entry.internal_id} /> </li>
						</ul>
					</div>

					{/* GROUP ADMINS */}
					<PSPageSegment heading="Group Admins">

						{/* params: /group_id */}
						{/* <Listing
							apiPath="groups/users-admin"
							apiParams={entry.entry.internal_id}
							type="row"
							onTotalChange={handleTotal}
						/> */}
						
					</PSPageSegment>
					
					{/* GROUP MEMBERS */}
					<PSPageSegment heading="Group Members">

						{/* params: /group_id */}
						{/* <Listing
							apiPath="groups/users-member"
							apiParams={entry.entry.internal_id}
							type="row"
							onTotalChange={handleTotal}
						/> */}
						
					</PSPageSegment>
					

					{/* GROUP TIMELINE */}
					<PSPageSegment heading="Group Member Updates">

						{/* <Listing
							apiPath="groups/timeline"
							apiParams={entry.entry.internal_id}
							type="card"
							onTotalChange={handleTotal}
						/>
						 */}
					</PSPageSegment>

					{/* GROUP EVENTS */}
					<PSPageSegment heading="Events related to this group">

						{/* params: /group_id */}
						{/* <Listing
							apiPath="groups/events"
							apiParams={entry.entry.internal_id}
							type="row"
							onTotalChange={handleTotal}
						/> */}
						
					</PSPageSegment>

					{/* GROUP ADMIN CARS */}
					<PSPageSegment heading="Member Cars">

						{/* params: /group_id */}
						{/* <Listing
							apiPath="groups/cars"
							apiParams={entry.entry.internal_id}
							type="card"
							onTotalChange={handleTotal}
							mobileCarousel
						/> */}
						
					</PSPageSegment>

					
					{/* GROUP ADMIN LISTINGS */}
					<PSPageSegment heading="Group Listings">

						{/* params: /group_id */}
						{/* <Listing
							apiPath="groups/listings"
							apiParams={entry.entry.internal_id}
							type="card"
							onTotalChange={handleTotal}
							mobileCarousel
						/> */}
						
					</PSPageSegment>
				

					{/* GROUP WANT-ADS */}
					<PSPageSegment heading="Group Want-ads">

						{/* params: /group_id */}
						{/* <Listing
							apiPath="groups/wants"
							apiParams={entry.entry.internal_id}
							type="card"
							onTotalChange={handleTotal}
							mobileCarousel
						/> */}
						
					</PSPageSegment>

					<Comments 
						entry_type={entry.entry.entry_type} 
						entry_id={entry.entry.internal_id}
					/>
					

				</div>

			</PSPage>
		</>
  );
};

export default Group;
