import { FaCar } from "react-icons/fa6";
const ORSYearMakeModel = ({ entry }) => {

	return (
		<span className="block text-[13px] my-1 text-grey font-bold w-full">
			{entry?.make && entry?.model ? (
				<span className="flex items-center">
					<span className="relative mr-[3px]">
						<FaCar />
					</span>

					{entry.year && <span className="relative ml-1">{entry.year}</span>}
					{entry.make && <span className="relative ml-1">{entry.make}</span>}
					{entry.model && <span className="relative ml-1">{entry.model}</span>}
					{entry.trim && entry.trim !== "null" && (
						<span className="relative ml-1">{entry.trim}</span>
					)}
				</span>
			) : (
				<span className="flex items-center">
					<span className="relative">
						<FaCar />
					</span>
					<span className="relative ml-2">Multiple or Generic</span>
				</span>
			)}
		</span>
	);
};

export default ORSYearMakeModel;
