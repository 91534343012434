import "./App.css";

import { React, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { DataCacheProvider } from './cache/DataCacheContext';
import { AuthProvider } from './providers/AuthContext';
import { Provider } from 'react-redux'
import store from './store/store'
import { PostPaneProvider } from './providers/PostPaneContext';
import PostPane from './components/modals/PostPane';
import { LikesProvider } from "./providers/LikesContext";
import { initGA, logPageView } from "./services/ga/ga";

// home
import Home from "./pages/Home";
import Welcome from "./pages/Welcome";
import ProtectedRoute from './routing/ProtectedRoute'

// app pages
import Car from "./pages/cars/Detail";
import CarCreate from "./pages/cars/Create";
import PSGlobalHeader from "./components/globals/layout/ORSHeader";
import Footer from "./components/globals/layout/footer/Footer";
import Users from "./pages/users/Index";
import User from "./pages/users/Detail";
import UserGaragePage from "./pages/users/Garage";
import UserTimelinePage from "./pages/users/Timeline";
import Group from "./pages/groups/Detail";
import Event from "./pages/events/Detail";
import Project from "./pages/projects/Detail";

import Posts from "./pages/posts/Index";
import Post from "./pages/posts/Detail";

// top level pages
import Cars from "./pages/cars/Index";
import CarsUser from "./pages/cars/Users";
import CarsBrandIndex from "./pages/cars/BrandIndex";
import CarsBrand from "./pages/cars/Brand";
import CarsModel from "./pages/cars/Model";
import CarsSpotted from "./pages/cars/Spotted";

import Society from "./pages/society/Index";
import Members from "./pages/society/Members";
import Groups from "./pages/society/Groups";
import Events from "./pages/society/Events";
import Rallys from "./pages/society/Rallys";

import Articles from "./pages/articles/Index";
import ArticlesCategory from "./pages/articles/Category";
import Article from "./pages/articles/Detail";
import ArticleCreate from "./pages/articles/Create";

import Marketplace from "./pages/marketplace/Index";
import MarketplaceListings from "./pages/marketplace/Listings";
import MarketplaceWantAds from "./pages/marketplace/Wants";

// entry create
import ProjectCreate from "./pages/projects/Create";
import GroupCreate from "./pages/groups/Create";
import EventCreate from "./pages/events/Create";

// Post
import PostCreate from "./pages/posts/Create";

// marketing 
import About from "./pages/marketing/About";
import Features from "./pages/marketing/Features";
import Changelog from "./pages/marketing/Changelog";
import Roadmap from "./pages/marketing/Roadmap";
import Support from "./pages/marketing/Support";
import History from "./pages/marketing/History";

// auth
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import PrivateRegistration from "./pages/auth/PrivateRegistration";
import ResetPassword from "./pages/auth/PasswordReset";
import Overview from "./pages/dashboard/Overview";
import AdminArticles from "./pages/dashboard/Articles";
import Garage from "./pages/dashboard/Garage";
import Settings from "./pages/dashboard/Settings";
import Followers from "./pages/dashboard/Followers";
import Following from "./pages/dashboard/Following";

import ScrollToTop from "./components/globals/utils/ScrollToTop";


function App() {

  const ConditionalFooter = () => {
    const location = useLocation();
    const shouldHideFooter = location.pathname.includes('/new/') || location.pathname.includes('/edit/');
    return !shouldHideFooter && <Footer />;
  };

  const GoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
      initGA();
    }, []);
  
    useEffect(() => {
      logPageView(location.pathname);
    }, [location]);
    
  };

  return (
    <div className="max-w-[1800px] mx-auto">

      <Provider store={store}>
        <AuthProvider>
          <LikesProvider>
            <DataCacheProvider>        
              <PostPaneProvider>
                <BrowserRouter>

                  <GoogleAnalytics />
                  <PSGlobalHeader />
                    <div className="relative pt-[105px] md:pt-[65px] main-page">
                      <ScrollToTop />

                      <Routes>

                        {/* home */}
                        <Route path="/" element={<Welcome />} />
      
                        {/* auth routes routes */}
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/private-registration-01ece3e149e54ebca6bb78a1569ca852" element={<PrivateRegistration />} />
                        <Route path="/reset-password/:userid" element={<ResetPassword />} />

                        <Route element={<ProtectedRoute />}>
                          <Route path="/home" element={<Home />} />
                          <Route path="/car/:id" element={<Car />} />
                          <Route path="/users" element={<Users />} />
                          <Route path="/users/:username" element={<User />} />
                          <Route path="/users/:username/timeline" element={<UserTimelinePage />} />
                          <Route path="/users/:username/garage" element={<UserGaragePage />} />

                          <Route path="/groups" element={<Groups />} />
                          <Route path="/groups/:id" element={<Group />} />
                          <Route path="/events" element={<Events />} />
                          <Route path="/event/:id" element={<Event />} />

                          <Route path="/project/:id" element={<Project />} />

                          <Route path="/posts" element={<Posts />} />
                          <Route path="/post/:id" element={<Post />} />

                          <Route path="/dashboard/overview" element={<Overview />} />
                          <Route path="/dashboard/settings" element={<Settings />} />
                          <Route path="/dashboard/garage" element={<Garage />} />
                          <Route path="/dashboard/articles" element={<AdminArticles />} />
                          <Route path="/dashboard/followers" element={<Followers />} />
                          <Route path="/dashboard/following" element={<Following />} />

                          <Route path="/cars" element={<Cars />} />
                          <Route path="/cars/users" element={<CarsUser />} />
                          <Route path="/cars/brands/" element={<CarsBrandIndex />} />
                          <Route path="/cars/brands/:brand" element={<CarsBrand />} />
                          <Route path="/cars/brand/:brand/model/:model" element={<CarsModel />} />
                          <Route path="/cars/spotted" element={<CarsSpotted />} />

                          <Route path="/society" element={<Society />} />
                          <Route path="/society/members" element={<Members />} />
                          <Route path="/society/groups" element={<Groups />} />
                          <Route path="/society/events" element={<Events />} />
                          <Route path="/society/rallys" element={<Rallys />} />

                          <Route path="/marketplace" element={<Marketplace />} />
                          <Route path="/marketplace/listings" element={<MarketplaceListings />} />
                          <Route path="/marketplace/wants" element={<MarketplaceWantAds />} />

                        </Route>
                        
                        <Route element={<ProtectedRoute />}>

                          {/* Create & edit routes */}
                          <Route path="/new/post" element={<PostCreate />} />
                          <Route path="/edit/post/:id" element={<PostCreate edit />} />

                          <Route path="/new/group" element={<GroupCreate />} />
                          <Route path="/edit/group/:id" element={<GroupCreate edit />} />

                          <Route path="/new/event" element={<EventCreate />} />
                          <Route path="/edit/event/:id" element={<EventCreate edit />} />

                          <Route path="/new/car" element={<CarCreate />} />
                          <Route path="/edit/car/:id" element={<CarCreate edit />} />

                          <Route path="/new/project" element={<ProjectCreate />} />
                          <Route path="/edit/project/:id" element={<ProjectCreate edit />} />

                          {/* article edit routes are protected */}
                          <Route path="/new/article" element={<ArticleCreate />} />
                          <Route path="/edit/article/:id" element={<ArticleCreate edit />} />
                          
                        </Route>


                        {/* marketing routes */}
                        <Route path="/about" element={<About />} />
                        <Route path="/features" element={<Features />} />
                        <Route path="/roadmap" element={<Roadmap />} />
                        <Route path="/changelog" element={<Changelog />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/history" element={<History />} />

                        {/* article front-end routes are not protected */}
                        <Route path="/articles" element={<Articles />} />
                        <Route path="/articles/category/:category" element={<ArticlesCategory />} />
                        <Route path="/article/:id" element={<Article />} />

                      </Routes>
                    </div>

                    <ConditionalFooter />

                    <PostPane />

                </BrowserRouter>
              </PostPaneProvider>
            </DataCacheProvider>
          </LikesProvider>
        </AuthProvider>
      </Provider>
    </div>
  );
}

export default App;
