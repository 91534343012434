import { React } from "react"
import PSPage from "components/globals/ui/layout/Page"

import matt from "assets/images/matt.jpg";
import jessica from "assets/images/jessica.jpg";
import aboutMain from "assets/images/about/main.jpg";


const About = () => {

  return (
    <PSPage>
      <div className="article-wysiwyg">
        <h1>About the Open Road Society</h1>
        <p>
          The Open Road Society is an online home for car enthusiasts. 
          We created a digital garage and community based in the northwest 
          that lets you access local groups, meets, and drives. Creating 
          your digital garage allows you to maintain historical records 
          for your cars, as well as share mods, restorations, and photo 
          shoots with your group of friends.
        </p> 

        <p>
          We also curate a marketplace focused solely on cars and parts, 
          where you can list rare and hard to find parts for sale, list 
          cars for sale, and create want-ads for others to see. 
        </p>

        <p>
          Sharing enthusiasm for car culture is at the heart of what 
          the society is all about. We want to cut through the 
          never-ending online noise and create a simple, focused, 
          and dedicated space to spend time with friends who give a 
          damn about the same thing. <strong><em>If we don’t drive together, 
          then we’re going to die alone.</em></strong>
        </p>

        

      </div>  

        <div className="flex items-center flex-wrap md:flex-nowrap mb-sm my-lg w-full max-w-[800px] mx-auto pt-lg">
          <div className="relative w-full md:w-1/2 rounded-full overflow-hidden aspect-square max-w-[300px] mx-auto">
            <img className="absolute inset-0" src={matt} alt="" />
          </div>
          <div className="w-full md:w-1/2 my-xs max-w-[450px] mx-auto px-xs">
            <h2 className="text-2xl font-bold">Matt</h2>
            <p className="my-2 italic">Founder</p>
            <p className="mb-2">Obsessed ever since being a 6 year old in a theatre watching Back to the Future. Porsche blood in my veins. Style over speed. Pop up headlights forever.</p>
            <p>Blank Check Cars: <strong>Porsche GT1, DeLorean</strong></p>
          </div>
        </div>

        <div className="flex items-center flex-wrap md:flex-nowrap mb-sm my-lg w-full max-w-[800px] mx-auto pt-lg">
          <div className="hidden md:block w-full md:w-1/2 my-xs max-w-[450px] mx-auto px-xs">
            <h2 className="text-2xl font-bold">Jessica</h2>
            <p className="my-2 italic">Founder</p>
            <p className="mb-2">I am a girl born to a car dad, and who married a car guy. I've always liked driving things that made me happy, and who wants to drive boring cars?</p>
            <p>Blank Check Cars: <strong>Land Rover Defender 110 in Alpine White</strong></p>
          </div>
          <div className="relative w-full md:w-1/2 rounded-full overflow-hidden aspect-square max-w-[300px] mx-auto">
            <img className="absolute inset-0" src={jessica} alt="" />
          </div>
          <div className="block md:hidden w-full md:w-1/2 my-xs max-w-[450px] mx-auto px-xs">
            <h2 className="text-2xl font-bold">Jessica</h2>
            <p className="my-2 italic">Founder</p>
            <p className="mb-2">I am a girl born to a car dad, and who married a car guy. I've always liked driving things that made me happy, and who wants to drive boring cars?</p>
            <p>Blank Check Cars: <strong>Land Rover Defender 110 in Alpine White</strong></p>
          </div>
        </div>     


        <div className="max-w-[800px] mx-auto mb-md mt-[150px]">
          <img src={aboutMain} alt='about' />  
        </div> 
    </PSPage>
  );
};

export default About;
