import React from 'react';
import { useAuth } from 'providers/AuthContext';
import { DashboardTemplate } from './Dashboard';
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import { NavLink } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";

export default function Following() {

	const { userInfo, isFetching } = useAuth();

	const sections = [
		{ 
			params: {
				elementId: "following", 
				headingTitle: "Users You Follow",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: "follow/following",
				omit: "none",
				limit: "24",
				type: "user", 
				pagination: true,
				createButton: false,
				createButtonPath: null
			},
			displayOptions: {
				layout: "row",
				yoursBadge: true,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: false,
				mobileCarousel: false,
				likes: true,
			}
		}
	]

	return (
		<>
			{!isFetching && userInfo ? (
				<DashboardTemplate userInfo={userInfo} title="Users Following You">

					<div className="text-center my-md">
						<p className="text-2xl mb-3">Coming soon</p>
						<NavLink to='/roadmap'>
							<Button variant="primary">View Roadmap</Button>
						</NavLink>
					</div>
					

					{sections.map(({params, displayOptions}) => (
						<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
					))}
					
				</DashboardTemplate>
			) : null }
		</>
	);
}