import { React, memo } from "react";
import { Link } from "react-router-dom";
import Single from "components/globals/image/Single";
// import { useSelector, shallowEqual } from 'react-redux';

function AddonRow({addon}) {
	// const isLoggedIn = useSelector(state => state.auth.isLoggedIn, shallowEqual);

	return (
		<>
			<div className="
				flex
        relative
				border-2
				border-accent
				p-3" 
				key={addon.internal_id}
			>
				
				<div>
					
					<Link className="flex items-start w-full overflow-hidden" to={`/project/${addon.project_id}`}>
						<div>
							<Single image={addon?.gallery} />
						</div>

						<div>
							<p>{addon.title}</p>
						</div>
					</Link>

				</div>
			</div>
		</>
  );
}

export default memo(AddonRow);
