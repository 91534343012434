import React from "react";
// import Listing from "components/globals/Listing"
import PSPage from "components/globals/ui/layout/Page"

export default function UsersPage() {
  return (
    <>
      <PSPage>
        <h1>Users</h1>
        
        {/* params: none yet */}
        {/* <Listing
          apiPath="users"
          apiParams=""
          type="card"
        /> */}
      </PSPage>
    </>
  );
};
