import React from "react";
import { NavLink } from "react-router-dom";

export function ORSBreadcrumbs({children}) {
	return (
		<div className="bg-secondary pt-1 pb-2 px-2 border-b-[1px] border-b-[#bbb]">
			<ul className="flex items-center max-w-[1200px] mx-auto text-[12px] p-0">
				{children}
			</ul>
		</div>
	);
};

export function BreadcrumbLink({to, label}) {
	return (
		<NavLink to={to} className="inline-block px-1 underline font-bold">{label}</NavLink>
	);
};


export function BreadcrumbSpan({label}) {
	return (
		<span className="inline-block px-1 italic">{label}</span>
	);
};


export function BreadcrumbSpacer() {
	return (
		<span className="inline-block px-1 opacity-[.2] italic">/</span>
	);
};
