import React, { useState } from 'react';
import { FaAngleDown } from "react-icons/fa";
import AnimateHeight from 'react-animate-height';

const PSCollapsableContainer = ({ startShown, children }) => {
  const [isOpen, setIsOpen] = useState(startShown);
  const [height, setHeight] = useState(startShown ? 'auto' : 0);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
    setHeight((prevHeight) => (prevHeight === 0 ? 'auto' : 0));
  };

  return (
    <div className="bg-[#ffffff] rounded-lg px-3 mb-3">
      {React.Children.map(children, (child) => {
        if (child.type === PSCollapsableContainerHeading) {
          return React.cloneElement(child, { onToggle: toggleOpen, isOpen });
        }
        if (child.type === PSCollapsableContainerContent) {
          return (
            <AnimateHeight duration={500} height={height}>
              {child}
            </AnimateHeight>
          );
        }
        return child;
      })}
    </div>
  );
};


const PSCollapsableContainerHeading = React.forwardRef(({ isOpen, onToggle, className, inset, children }, ref) => (
  <div onClick={onToggle} className="cursor-pointer px-3 py-2 flex items-center">
    <div className="grow">
			{children}
		</div>

		<div 
			className="ml-2 transition"
			style={{
				transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
			}}>
			<FaAngleDown className="text-2xl" />
		</div>
  </div>
))

const PSCollapsableContainerContent = React.forwardRef(({ className, inset, children }, ref) => (
  <div className="px-3 py-1">
    {children}
  </div>
))
 
export {
  PSCollapsableContainer,
	PSCollapsableContainerHeading,
	PSCollapsableContainerContent
}