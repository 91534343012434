import React, {useState, useEffect} from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";
import { useFetchData } from "helpers/utils";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import ORSDetailPageMarquee from "components/globals/layout/detail/marquee";

export default function CarsBrand() {
	const { brand } = useParams();
	const [models, setModels] = useState([]);
	const url = `${process.env.REACT_APP_SERVER_URI}/api/garage/brands/brand/${brand}/models`;

	const { data, error, loading } = useFetchData(url, [0]);

	useEffect(() => {
		if (data?.models) {
			setModels(data.models);
		}
	}, [data]);

	const sections = [
    { 
      params: {
				elementId: "carsByBrand", 
				headingTitle: `${brand} cars on the site`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `garage/related/make/${brand}`,
				omit: "none",
				limit: "12",
				type: "car", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    },
		{ 
      params: {
				elementId: "listingsByBrand", 
				headingTitle: `${brand} parts for sale`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/listing/make/${brand}`,
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    },
		{ 
      params: {
				elementId: "wantsByBrand", 
				headingTitle: `${brand} want-ads`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/want/make/${brand}`,
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    },

		{ 
      params: {
				elementId: "activityByBrand", 
				headingTitle: `${brand} posts/records on the site`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/record/make/${brand}`,
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: true,
        userBadge: true,
        carBadge: true,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    },

		{ 
      params: {
				elementId: "spottedByBrand", 
				headingTitle: `${brand} spotted`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/spot/make/${brand}`,
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
				createButton: false,
        createButtonPath: null,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
				likes: true,
      }
    }
  ]

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			<ORSSubnav>
				<SubnavItem to="/cars" label="Cars Home" />
				<SubnavItem active to="/cars/brands" label="Brands" />
				<SubnavItem to="/cars/users" label="User Cars" />
				<SubnavItem to="/cars/spotted" label="Spotted" />
			</ORSSubnav>

			<ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/cars" label="Cars" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/cars/brands" label="Brands" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label={brand} />
			</ORSBreadcrumbs>

			<ORSDetailPageMarquee
				banners={[{
					filename: `/make/${brand}/banner.jpg`
				}]} 
				gallery={[{
					filename: `/make/${brand}/profile.jpg`
				}]} staticImages>
				<h1 className="text-3xl font-bold uppercase my-3">{brand}</h1>
			</ORSDetailPageMarquee>

			<PSPage>

				<h2 className="text-xl font-bold uppercase">{brand} Models</h2>

				<div className="grid grid-cols-2 lg:grid-cols-3 gap-4 my-4">
					{models.map((model) => {
						return (
							<div
								className="flex relative mx-0 my-3 shadow-[0_0_15px_10px_rgba(0,0,0,0.06)]"
								key={model.model_handle}
							>
								<NavLink
									className="relative w-full overflow-hidden h-full p-3 md:p-5 group hover:bg-brg transition"
									to={`/cars/brand/${model.make_handle}/model/${model.model_handle}`}
								>
									<p className="uppercase font-bold text-[13px] mb-0 text-grey group-hover:text-white transition">{model.make_handle}</p>
									<h4 className="text-2xl md:text-3xl font-serif text-brg group-hover:text-white transition">{model.model}</h4>
								</NavLink>
							</div>
						);
					})}
				</div>

				{sections.map(({params, displayOptions}) => (
					<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
				))}

			</PSPage>
		</>
	);
};
