import React from 'react';
import { Input } from "components/globals/ui/input/Input";
import { Label } from "components/globals/ui/input/Label";
import { InputGroup } from "components/globals/ui/input/Group";

export default function PSInputText({ 
  register, 
  name, 
  label, 
  type, 
  required, 
  error, 
  onBlur 
}) {
  // Create a custom registration with added onBlur handler
  const registerWithBlur = register(name);
  
  // Combine the default register's onBlur with our custom onBlur
  const handleBlur = (e) => {
    // Execute the react-hook-form's built-in onBlur
    if (registerWithBlur.onBlur) {
      registerWithBlur.onBlur(e);
    }
    
    // Execute our custom onBlur if provided
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <InputGroup>
      <Label htmlFor={name}>
        <span>{label}</span>
        {required && (
          <span className="font-bold text-red">*</span>
        )}
      </Label>
      <Input
        required={required}
        type={type}
        {...registerWithBlur}
        onBlur={handleBlur}
        id={name}
      />
      {error && (
        <div className="text-red text-sm mt-1">{error}</div>
      )}
    </InputGroup>
  );
}