import { React } from "react"
import PSPage from "components/globals/ui/layout/Page"

const Features = () => {

  return (
    <>
      <PSPage>
        <div class="article-wysiwyg">
        <h2>Digital Garage</h2>
        <p>
          You can create and manage your garage online. 
          This includes maintaining lists of mods, 
          maintenance, and restorations. You can create 
          projects associated with your cars for future 
          work, upgrades, routine maintenance, and more. 
        </p>
        <h2>Car Hubs</h2>
        <p>
          All cars on the site feed into brand and model 
          pages where you can see all the content on the 
          site scoped to specific cars. These are valuable 
          pages for people to see activity related to cars 
          they have, which can be bookmarked for recurring 
          updates.
        </p>
        <h2>Marketplace</h2>
        <p>
          Maintaining cars, wether modern or classics, will 
          always become increasingly more difficult as parts 
          availability become more rare by the year. Our 
          marketplace is designed to be a focused place for 
          members to list things they have for sale so they 
          don’t collect dust in the back of a garage. In addition, 
          creating want-ads is a great way for someone to know 
          what you need. 
        </p>
        <h2>Society</h2>
        <p>
          The reason we’re all car enthusiasts is because we want 
          to use them. The society pays special attention to 
          showcasing and providing events, meetups, drives, 
          car shows and more. We all live in the northwest, 
          and we’re all looking for great places to, and the 
          society is a great way to bring all of the local 
          events together under one roof.
        </p>
        </div>

      </PSPage>
    </>
    
  );
};

export default Features;
