import { React } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import Listing from "components/globals/Listing";
import PSPage from "components/globals/ui/layout/Page"
import { Button } from 'components/globals/ui/input/Button';
import { useFetchData } from "helpers/utils";

const UserTimelinePage = () => {
  const { username } = useParams();
  const { data: user, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/users/publicUser/${username}`,
		[username],
	);

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  return (
    <PSPage>
      <div>
        <h1>Timeline for user: {user.username}</h1>
        <Link to={`/users/${user.username}`}>
          <Button variant='outline'>
            BACK TO USER PAGE
          </Button> 
        </Link>

        {/* params: /id */}
        {/* <Listing
          apiPath="users/timeline"
          apiParams={user.user_id}
          type="card"
        /> */}
        
      </div>


    </PSPage>
  );
};

export default UserTimelinePage;
