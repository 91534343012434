import React from 'react';
import * as Switch from '@radix-ui/react-switch';
import { Controller } from 'react-hook-form';

const PSLightswitch = ({ label, control, name, onChange }) => (
	<div className="">
		<label className="text-[15px] leading-none pr-[15px] cursor-pointer mb-2 block" htmlFor={label}>
			{label}
		</label>
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<Switch.Root
					className="
						w-[42px] 
						h-[25px] 
						bg-[#ccc] 
						rounded-full 
						relative 
						outline-none cursor-pointer"
					id={name}
					checked={field.value}
					onCheckedChange={(val) => {
						field.onChange(val);
						if (onChange) onChange(val); 
					}}
				>
					<Switch.Thumb 
						className="
							block 
							w-[21px] 
							h-[21px] 
							bg-[#333] 
							rounded-full 
							transition-transform 
							duration-100 
							translate-x-0.5 
							will-change-transform 
							data-[state=checked]:translate-x-[19px] 
							data-[state=checked]:bg-guards" />
				</Switch.Root>
			)}
		/>
	</div>
);

export default PSLightswitch;