import { React, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "components/globals/ui/input/Button";
import { BsXCircle } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const Pane = ({ visible, children, onClose, actions, preventClose = false }) => {
  const paneRef = useRef(null);
  const contentRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (visible && paneRef.current) {
      disableBodyScroll(contentRef.current);
     } else {
      clearAllBodyScrollLocks();
    }
    return () => clearAllBodyScrollLocks();
  }, [visible]);

  // URL change detection
  useEffect(() => {
    clearAllBodyScrollLocks();
    if(onClose) { onClose(); }
  }, [location.pathname]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="fixed top-0 left-0 w-[100vw] h-[100vh] z-50 flex justify-end"
          ref={paneRef}
        >
          {/* BG */}
          <motion.div
            className="bg-secondary absolute inset-0 z-[1]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
            onClick={!preventClose ? onClose : null}
          ></motion.div>

          {/* CONTENT PANE */}
          <motion.div
            ref={contentRef}
            className="
              shrink-0
              bg-white 
              w-[93vw] 
              lg:w-1/2 
              absolute 
              right-0 
              top-0 
              z-[2]
              p-sm
              overflow-y-auto
              -webkit-overflow-scrolling: touch
              h-full"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{
              duration: 0.5,
              ease: [0.33, 1, 0.68, 1],
            }}
            style={{
              WebkitOverflowScrolling: 'touch', // Ensure smooth scrolling on iOS
              overscrollBehavior: 'contain' // Prevent scroll chaining
            }}
          >
            {!preventClose && (
              <Button
                onClick={onClose}
                className="
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                  w-[30px]
                  h-[0px]
                  rounded-full
                  bg-secondary
                  transition
                  absolute
                  top-5
                  right-5
                "
              >
                <BsXCircle className="text-2xl top-4 text-black shrink-0" />
              </Button>              
            )}

            <div className="relative max-w-lg w-11/12 mx-auto">
              <div>
                {children}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Pane;