import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";

export default function Articles() {

	const sections = [
    { 
      params: {
				elementId: "allArticles", 
				headingTitle: "Articles",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: "article",
				omit: "none",
				limit: "24",
				type: "article", 
				pagination: true,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    }
  ]


  return (
    <>

      <ORSSubnav>
        <SubnavItem active to="/articles" label="All" />
				{/* <SubnavItem to="/articles" label="Categories Here" /> */}
      </ORSSubnav>

      <ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label="Articles" />
      </ORSBreadcrumbs>
{/* 
      <div className="relative">
        <div className="bg-black text-white p-lg text-3xl font-bold">Featured Articles Here</div>
      </div> */}

      <PSPage>
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
};
