import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "assets/images/logo.svg";
import { useAuth } from "providers/AuthContext";
import { Button } from "components/globals/ui/input/Button";
import { FaPlus } from "react-icons/fa6";
import PSCircle from "components/globals/ui/PSCircle";
import Single from "components/globals/image/Single";
import Pane from "components/modals/Pane";
import LoginForm from "components/globals/user/LoginForm";
import { FaInstagram, FaYoutube } from "react-icons/fa";


const ORSMainNav = ({ inverted = false }) => {
  return (
    <div className="flex items-center grow justify-start gap-2 md:gap-4 ml-2 md:ml-4 md:ml-6 grow">
      <NavLink to="/cars" className={({ isActive }) => "button"}>
        {({ isActive }) => (
          <Button
            variant={isActive ? (inverted ? "invertedOutlineActive" : "outlineActive") : (inverted ? "invertedOutline" : "outline")}
          >
            Cars
          </Button>
        )}
      </NavLink>

      <NavLink to="/society" className={({ isActive }) => "button"}>
        {({ isActive }) => (
          <Button
            variant={isActive ? (inverted ? "invertedOutlineActive" : "outlineActive") : (inverted ? "invertedOutline" : "outline")}
          >
            Society
          </Button>
        )}
      </NavLink>

      <NavLink to="/marketplace" className={({ isActive }) => "button"}>
        {({ isActive }) => (
          <Button
            variant={isActive ? (inverted ? "invertedOutlineActive" : "outlineActive") : (inverted ? "invertedOutline" : "outline")}
          >
            <span className="hidden md:block">Marketplace</span>
            <span className="block md:hidden">Market</span>
          </Button>
        )}
      </NavLink>

      <NavLink to="/articles" className={({ isActive }) => "button"}>
        {({ isActive }) => (
          <Button
            variant={isActive ? (inverted ? "invertedOutlineActive" : "outlineActive") : (inverted ? "invertedOutline" : "outline")}
          >
            Articles
          </Button>
        )}
      </NavLink>
    </div>
  );
};


const ORSProfile = ({profileImage}) => {
  return (
    <NavLink to="/dashboard/overview">
      <PSCircle>
        <Single
          key={profileImage}
          image={profileImage}
          fill
        />
      </PSCircle>
    </NavLink>
  )
}

const ORSHeader = () => {
  const { userInfo, isFetching } = useAuth();
  const [profileImage, setProfileImage] = useState(null);
  const [loginPane, setLoginPane] = useState(false);
  const [newPane, setNewPane] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (userInfo) {
      setTimeout(function () {
        setProfileImage(userInfo?.gallery[0]);
      }, 500);
    }
  }, [userInfo]);

  const showLogin = function () {
    setLoginPane(true);
  };

  const hideLogin = function () {
    setLoginPane(false);
  };

  const showNewPane = () => { 
    console.log('in');
    setNewPane(true);
  }

  const hideNewPane = () => {
    setNewPane(false);
  }

  return (
    <>
      <header
        className={`${
          location.pathname === "/" ? "" : ""
        } block fixed left-0 w-[100vw] z-[10] bg-background border-b-[1px] border-brg text-foreground flex items-center flex-wrap md:flex-nowrap`}
      >
        <div className="flex items-center justify-between w-main h-[65px]">
          
          {!isFetching && (
            <div className="md:flex-none">
              <NavLink to={userInfo ? "/home" : "/"}>
                <img src={logo} alt="Open Road Society" className="block h-[26px] md:h-[35px] w-auto" />
              </NavLink>
            </div>
          )}
          
          {userInfo ? (
            <div className="hidden md:block grow">
              <ORSMainNav />
            </div>
          ) : (
            <div className="mx-3 grow flex justify-start">
              <NavLink
                to="/articles"
                className={({ isActive }) => "button"}
              >
                {({ isActive }) => (
                  <Button variant={isActive ? "outlineActive" : "outline"}>Articles</Button>
                )}
              </NavLink>
            </div>
          )}

          <div className="flex items-center">
            {!isFetching && location.pathname === "/" && (
              <>
                {userInfo ? (
                  <ORSProfile profileImage={profileImage} />
                ) : (
                  <NavLink className="button" onClick={showLogin}>
                    <Button variant="outline">Login</Button>
                  </NavLink>
                )}
                <a
                  href="https://instagram.com/open.road.society/"
                  target="blank"
                  className="block text-[30px] ml-4 text-brg"
                >
                  <FaInstagram />
                </a>

                <a
                  href="https://www.youtube.com/@open.road.society"
                  target="blank"
                  className="block text-[30px] ml-4 text-brg"
                >
                  <FaYoutube />
                </a>
              </>
            )}

            {!isFetching && location.pathname !== "/" && (
              <>
                {userInfo ? null : (
                  <NavLink className="button" onClick={showLogin}>
                    <Button variant="outline">Login</Button>
                  </NavLink>
                )}

                <span>
                  {userInfo ? (
                    <div className="flex items-center">
                      <ORSProfile profileImage={profileImage} />
                      <div onClick={showNewPane}>
                        <PSCircle className="bg-guards text-white relative z-[2]" >
                          <FaPlus className="text-[25px] pointer-events-none" />
                        </PSCircle>
                      </div>
                    </div>
                  ) : null}
                </span>
              </>
            )}
          </div>
        </div>

        
          
          {userInfo && (
            <div className="block md:hidden h-[40px] bg-brg w-full flex items-center">
              <ORSMainNav inverted />
            </div>
          )}        

      </header>

      <Pane visible={newPane} onClose={hideNewPane}>
        <div className="relative mt-6">

          <NavLink className="button" to="/new/post">
            <div className="bg-guards p-3 text-white rounded-lg pt-lg relative">
              <span className="flex items-center absolute top-2 left-2 mr">
                <FaPlus className="mr-1" />
                <span className="text-[12px] font-bold">NEW</span>
              </span>
              <span className="text-3xl font-bold">Post</span>
              <div className="font-mono text-[12px]">Car record, spotted, listing, want-ad, maintenance, etc</div>
            </div>
          </NavLink>

          <div className="grid grid-cols-2 gap-2 mt-2">
            <NavLink className="button" to="/new/car">
              <div className="bg-speed p-3 text-black rounded-lg pt-lg relative">
                <span className="flex items-center absolute top-2 left-2 mr">
                  <FaPlus className="mr-1" />
                  <span className="text-[12px] font-bold">NEW</span>
                </span>
                <span className="text-2xl font-bold">Garage Car</span>
              </div>
            </NavLink>

            <NavLink className="button" to="/new/project">
              <div className="bg-speed p-3 text-black rounded-lg pt-lg relative">
                <span className="flex items-center absolute top-2 left-2 mr">
                  <FaPlus className="mr-1" />
                  <span className="text-[12px] font-bold">NEW</span>
                </span>
                <span className="text-2xl font-bold">Project</span>
              </div>
            </NavLink>

            <NavLink className="button" to="/new/event">
              <div className="bg-speed p-3 text-black rounded-lg pt-lg relative">
                <span className="flex items-center absolute top-2 left-2 mr">
                  <FaPlus className="mr-1" />
                  <span className="text-[12px] font-bold">NEW</span>
                </span>
                <span className="text-2xl font-bold">Event</span>
              </div>
            </NavLink>
          </div>

          
        </div>
      </Pane>

      <Pane visible={loginPane} onClose={hideLogin}>
        <LoginForm onClose={hideLogin} />
      </Pane>
    </>
  );
};

export default ORSHeader;
