import React, {useState, useEffect} from "react";
import { Button } from 'components/globals/ui/input/Button';
import Pane from 'components/modals/Pane';
import { NavLink } from 'react-router-dom';
import { FaPlus, FaCheck } from "react-icons/fa6";
import {
  PSInputBase,
  PSWysiwyg,
	PSSubmit,
	PostTypes,
  PostCategories,
} from "components/globals/ui/input/PSInputs";
import { StandardErrorMessage } from "components/globals/messages/StandardMessages";
import PSGallery from "components/globals/PSGallery";
import {
  createFormData,
	clearPostData,
	setCategory,
} from "helpers/utils";
import {
	addonTypes,
	addonCategories,
  ProjectAddonSchema,
} from "types/projectTypes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createEntry, updateEntry } from "store/authSlice";


export function SuccessPane({title, onClose, visible, edit = false}) {
	return (
		<Pane visible={visible} onClose={onClose} preventClose>
			<h3 className="mb-4 text-lg font-bold">{title}</h3>
			<div className="flex items-center">
				{!edit && (
					<span className="inline-block mr-3 mb-3">
						<Button variant='primary' onClick={onClose}>
							<FaPlus />
							<span>Another</span>
						</Button>
					</span>
				)}
				
				<span className="inline-block mr-3 mb-3">
					<NavLink to={`/dashboard/overview`}>
						<Button variant='secondary'>Dashboard</Button>
					</NavLink>
				</span>
				
				<span className="inline-block mr-3 mb-3">
					<NavLink to={`/`}>
						<Button variant='secondary'>Home</Button>
					</NavLink>
				</span>
				
			</div>
		</Pane>
	)
};

export function ErrorPane({onClose, visible, message}) {
	return (
		<Pane visible={visible} onClose={onClose}>
			<p>{message}</p>
		</Pane>
	)
};

export function ProjectAddonPane({type, onClose, visible, edit = false, projectId}) {
	const userToken = useSelector((state) => state.auth.userToken);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const [resetRequested, setResetRequested] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [success, setSuccess] = useState(false);
	const [formData, setFormData] = useState(ProjectAddonSchema);

	useEffect(() => {
		if (!edit) {
			setNewPostDataProperty("type", addonTypes[0]?.key);
			setCategory(setNewPostDataProperty, addonCategories, addonTypes[0]?.key);
		}
	}, []);

	// always default to the first category in the list when
	// updating the post type
	useEffect(() => {
		if (formData.type) {
			setCategory(setNewPostDataProperty, addonCategories, formData.type);
		}
	}, [formData.type]);

	const reset = () => {
		clearPostData(setFormData, ProjectAddonSchema);
		setLoading(false);
		setErrorMessage(null);
		setResetRequested(true);
		setSuccess(false);
	};

	const onInputChange = (context, val) => {
    setNewPostDataProperty(context, val);
  };

  const updateSelectedFiles = (images) => {
    setNewPostDataProperty("gallery", images);
  };

  const setNewPostDataProperty = (context, val) => {
    setFormData((prevData) => ({
      ...prevData,
      [context]: val,
    }));
  };
	
	// const resetRequested = (context, val) => {
	// 	console.log('context', context, 'val', val)
	// }

	const handleResetComplete = () => {
    setResetRequested(false);
  };


	const onFormAddonSubmit = async () => {
		if (!userToken) return;

		if (!formData.title || formData.title.length <= 0) {
			alert("title required");
			return;
		}

		setLoading(true);
    setErrorMessage(null);

		// create the form data for processing
		let fd = createFormData(formData, ProjectAddonSchema);

		if (edit) {
			fd.append("internal_id", formData.internal_id);
		} else {
			fd.append("project_id", projectId);
		}

		try {
			const action = edit
				? updateEntry({ data: fd, entry_type: 'projectAddon' })
				: createEntry({ data: fd, entry_type: 'projectAddon' });
			await dispatch(action).unwrap();
			setSuccessMessage('success!');
			setSuccess(true);
			// reset();
		} catch (err) {
			alert(err.message || "Failed. Please try again.");
		}
	}

	return (
		<Pane visible={visible} onClose={onClose}>

			{success ? (
				<>
					<h3 className="text-2xl mb-6 flex items-center">
						<span className="w-[30px] h-[30px] rounded-full bg-green flex items-center justify-center mr-5">
							<FaCheck className="text-xl text-black" />
						</span>
						<span>Successfully created {formData?.type}: {formData?.title}</span>
					</h3>

					{!edit && (
						<Button variant='outline' onClick={reset}>
							<FaPlus />
							<span>Another</span>
						</Button>
					)}

					<Button variant='primary' onClick={reset}>
						<span>Done</span>
					</Button>

					<Button onClick={reset} className="ml-3" variant="outline">Cancel</Button>
				</>
			) : (
				<>
					<h3 className="text-2xl mb-xs">Project Item:</h3>
			
					<PostTypes
						postType={formData?.type || type}
						data={addonTypes}
						onInputChange={onInputChange}
					/>

					<PostCategories
						postType={formData?.type || type}
						postCategory={formData?.category}
						data={addonCategories}
						onInputChange={onInputChange}
					/>

					<PSInputBase
						name="title"
						label="Title"
						type="text"
						context="title"
						required
						value={formData?.title || ""}
						onChange={onInputChange}
					/>

					<PSGallery
						initialImages={formData?.gallery || []}
						onImagesChange={updateSelectedFiles}
						resetRequested={resetRequested}
						onResetComplete={handleResetComplete}
					/>

					<PSWysiwyg
						name="body"
						label="Body"
						context="body"
						value={formData?.body || ""}
						onChange={onInputChange}
					/>

					<div className="flex items-center">
						<PSSubmit
							onClick={onFormAddonSubmit}
							loading={loading}
							label={
								loading
									? edit
										? "Updating..."
										: "Creating..."
									: edit
									? `Update Project Item`
									: `Create Project Item`
							}
							disabled={loading}
						/>

						<Button onClick={onClose} className="ml-3" variant="outline">Cancel</Button>
					</div>

					{errorMessage && (
						<StandardErrorMessage msg={errorMessage} />
					)}
				</>
			)}
		</Pane>
	)
};

