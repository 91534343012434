import { React } from "react";
import { Link } from "react-router-dom";
import { useFetchData } from "helpers/utils";
import DataBadge from "components/globals/ui/ORSDataBadge"

const CarBadge = ({ car_id, noLink }) => {
  const {
    data: entry,
    loading,
    error,
  } = useFetchData(
    `${process.env.REACT_APP_SERVER_URI}/api/car/${car_id}`,
    [car_id]
  );

  if (!car_id) {
    return;
  }

  // Loading & error states
  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error: {error.message}</span>;


  return (
    <>
      {noLink ? (
        <DataBadge 
          image={entry?.entry?.gallery[0]} 
          title={entry?.entry?.title} 
        />
      ) : (
        <Link
          to={{ pathname: `/car/${entry?.entry?.internal_id}` }}
        >
          <DataBadge 
            image={entry?.entry?.gallery[0]} 
            title={entry?.entry?.title} 
          />
        </Link>
      )}
    </>
  );
};

export default CarBadge;
