import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "store/authSlice";
import PSInputText from "components/globals/ui/input/PSInputText";
import { PSSubmit } from "components/globals/ui/input/PSInputs";
import { StandardErrorMessage } from "components/globals/messages/StandardMessages";
import { Button } from "components/globals/ui/input/Button"

export default function Login({ onClose }) {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const form = useForm();
  const { handleSubmit } = form;
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      if (onClose) {
        onClose();
      }
      navigate("/home");
    }
  }, [navigate, userInfo, onClose]);

  const submitForm = (data) => {
    dispatch(userLogin(data));
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>

        <PSInputText
          register={form.register}
          name="email"
          label="Email Address"
          type="email"
        />
        <PSInputText
          register={form.register}
          name="password"
          label="Password"
          type="password"
        />

        <PSSubmit label="Login" loading={loading} />
        {error && <StandardErrorMessage msg={error} />}

        {/* <NavLink to="/register">
					<Button variant="link">Sign up for a new account</Button>
				</NavLink> */}

        <Button variant='outline'><a href="mailto:mattaebersold@gmail.com">Request an invitation</a></Button>
      </form>
    </>
  );
}
