export function StandardErrorMessage({ msg }) {
  return (
		<div 
			className="
				bg-red 
				px-3 
				py-1 
				rounded-lg 
				text-foreground 
				my-3
				font-bold"
			>
			<span>{msg}</span>
		</div>
  );
}