import React, { useEffect, useState } from "react";
import { PSSelect } from "components/globals/ui/input/PSInputs";
import { ErrorPane } from "components/modals/Panes";
import { fetchCarDB } from "helpers/utils";

export default function PSMakeAndModel({ edit = false, onChange, initialMake, initialModel }) {
	const [masterList, setMasterList] = useState([]);
	const [make, setMake] = useState("");
	const [model, setModel] = useState("");
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		const loadCarDBData = async () => {
			try {
				const data = await fetchCarDB();
				setMasterList(data.cars);
			} catch (err) {
				setErrorMessage(err.message);
			}
		};

		loadCarDBData();
	}, []);

	useEffect(() => {
		if (initialMake) {
			setMake(initialMake);
		}
		if (initialModel) {
			setModel(initialModel);
		}
	}, [initialMake, initialModel]);

	const onMakeChange = (name, value) => {
		setMake(value);
		setModel("");

		// Notify parent of changes
		if (onChange) {
			onChange({ make: value, model: "" });
		}
	};

	const onModelChange = (name, value) => {
		setModel(value);

		// Notify parent of changes
		if (onChange) {
			onChange({ make, model: value });
		}
	};

	return (
		<>
			<ErrorPane visible={errorMessage} message={errorMessage} />

			<PSSelect
				name="make"
				label="Make"
				firstItemLabel="Car Make"
				onChange={onMakeChange}
				value={make} // Ensure selection persists
			>
				{masterList.map((item) => (
					<option value={item.make} key={item.make}>
						{item.make}
					</option>
				))}
			</PSSelect>

			<div className="my-6"></div>

			<PSSelect
				name="model"
				label="Model"
				firstItemLabel="Car Model"
				onChange={onModelChange}
				value={model} // Ensure selection persists
			>
				{masterList
					.find((item) => item.make === make)
					?.models?.sort((a, b) => a.localeCompare(b))
					.map((model) => (
						<option value={model} key={model}>
							{model}
						</option>
					))}
			</PSSelect>
		</>
	);
}
