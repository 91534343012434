import React, { useState } from "react";
import { FaEllipsisH } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuth } from "providers/AuthContext";
import { getEntryTypePath } from "types/baseTypes";
import DeleteEntryUI from "components/globals/utils/DeleteEntryUI";
import { Button } from "components/globals/ui/input/Button";
import Pane from "components/modals/Pane";

export default function EntryUserActions({ comparison, item }) {
  const { userInfo, isFetching } = useAuth();
  const [visible, setVisible] = useState(false);

  const showPane = function () {
    setVisible(true);
  };

  const hidePane = function () {
    setVisible(false);
  };

  return (
    <>
      {!isFetching && comparison === userInfo?.user_id && (
        <div className="relative">
          <span
            className="
            relative 
            bg-secondary
            text-brg
            mt-2 
            overflow-hidden 
            rounded-small
            w-[30px]
            h-[30px]
            flex
            items-center
            justify-center
            cursor-pointer"
            onClick={showPane}
          >
            <FaEllipsisH className="relative" />
          </span>

          <Pane visible={visible} onClose={hidePane}>
            <NavLink
              to={`/edit/${getEntryTypePath(item?.entry_type)}/${item.internal_id}`}
            >
              <Button variant="primary">Edit</Button>
            </NavLink>
            <DeleteEntryUI entry={item} />
          </Pane>
        </div>
      )}
    </>
  );
}
