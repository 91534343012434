export function getEntryTypePath(entryType) {
  const typeMap = {
    listing: "post",
    garagecar: "car",
    project: "project",
    group: "group",
    record: "post",
    event: "event",
    post: "post",
    want: "post",
    update: "post",
    note: "post",
    spot: "post",
    document: "post",
    projectAddon: "project/addon",
    article: "article",
    comment: "comment",
    like: "like"
  };

  return typeMap[entryType] || "";
}
