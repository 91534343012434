export const Schema = {
  private: "",
  gallery: "",
  banenrs: "",
  title: "",
  body: "",
  type: "",
  category: "",
  car_id: "",
};

export const ProjectAddonSchema = {
  gallery: "",
  title: "",
  body: "",
  type: "",
  category: "",
};

export const associationTypes = ["garage"];

export const associationState = {
  garage: "",
};

export const idMap = {
  garage: "car",
};

export const types = [
  {
    key: "general",
    label: "General",
  },
  {
    key: "restoration",
    label: "Restoration",
  },
  {
    key: "maintenance",
    label: "Maintenance",
  },
  {
    key: "mod",
    label: "Mod",
  },
];

export const categories = [
  {
    type: "general",
    items: [],
  },

  {
    type: "restoration",
    items: [
      {
        key: "chassis",
        label: "Chassis",
      },
      {
        key: "engine",
        label: "Engine",
      },
      {
        key: "body",
        label: "Body",
      },
      {
        key: "electrical",
        label: "Electrical",
      },
      {
        key: "interior",
        label: "Interior",
      },
    ],
  },

  {
    type: "maintenance",
    items: [
      {
        key: "routine",
        label: "Routine",
      },
      {
        key: "repair",
        label: "Repair",
      },
      {
        key: "safety",
        label: "Safety/Emergency",
      },
    ],
  },

  {
    type: "mod",
    items: [
      {
        key: "performance",
        label: "Performance",
      },
      {
        key: "cosmetic",
        label: "Cosmetic",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },
];

export const addonTypes = [
  {
    key: "note",
    label: "Note",
  },
  {
    key: "todo",
    label: "To-Do",
  },
];

export const addonCategories = [
  {
    type: "note",
    items: [
      {
        key: "cat1",
        label: "Category 1",
      },
      {
        key: "cat2",
        label: "Category 2",
      },
    ],
  },

  {
    type: "todo",
    items: [
      {
        key: "cat1",
        label: "Category 1",
      },
      {
        key: "cat2",
        label: "Category 2",
      },
    ],
  },
]