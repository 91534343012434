import { React } from "react";

export default function TypeBadge({
	entryType,
	type,
	category
}) {

	const getBgColor = (entryType, type, category) => {
		if(entryType === 'post') {
			switch (type?.toLowerCase()) {
				case "listing":
					return "bg-badgeListing text-badgeListing-foreground";
				case "want":
					return "bg-badgeWant text-badgeWant-foreground";
				case "record":
					return "bg-badgeRecord text-badgeRecord-foreground";
				case "spot":
					return "bg-badgeSpot text-badgeSpot-foreground";
				case "update":
					return "bg-badgeUpdate text-badgeUpdate-foreground";
				default:
					return "bg-badgeDefault text-badgeDefault-foreground";
			}
		} else {
			switch (entryType?.toLowerCase()) {
				case "garage":
					return "bg-badgeGarage text-badgeGarage-foreground";
				case "event":
					return "bg-badgeEvent text-badgeEvent-foreground";
				case "group":
					return "bg-badgeGroup text-badgeGroup-foreground";
				default:
					return "bg-badgeDefault text-badgeDefault-foreground";
			}
		}
  };

	const getTypeString = (entryType, type, category) => {

		if(entryType === 'post') {
			switch (type?.toLowerCase()) {
				case "listing":
					return "Listing";
				case "want":
					return "Want-Ad";
				case "record":
					return "Car Record";
				case "spot":
					return "Spot";
				case "update":
					return "Update";
				default:
					return "Post";
			}
		} else {
			switch (entryType?.toLowerCase()) {
				case "garagecar":
					return "User Car";
				case "event":
					return "Event";
				case "group":
					return "Group";
				default:
					return "TEST";
			}
		}
  };

	return (
		<>
			{ type ? (
				<div 
					className={`
						py-[2px]
						px-[5px] 
						uppercase
						font-bold
						text-[10px]
						rounded-sm
						${getBgColor(entryType, type, category)}`}
					>
					{ getTypeString(entryType, type, category) }
				</div>
			) : null }
			
		</>
  );
}