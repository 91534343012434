import React from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";

import marquee from "assets/images/marquees/marketplace.jpg";
import marqueeMobile from "assets/images/marquees/marketplace-mobile.jpg";

export default function Marketplace() {

  const sections = [
    { 
      params: {
				elementId: "recentListings", 
				headingTitle: "Recent Listings",
				headingButtonUrl: "/marketplace/listings",
				headingButtonText: "View All",
				apiPath: "post/type/listing",
				omit: "none",
				limit: "6",
				type: "post", 
				pagination: false,
        createButton: true,
        createButtonPath: "/new/post"
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        userBadge: true,
        carBadge: true,
        carDetails: true,
        dateDetails: true,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    },
    { 
      params: {
				elementId: "recentWants", 
				headingTitle: "Recent Want-Ads",
				headingButtonUrl: "/marketplace/wants",
				headingButtonText: "View All",
				apiPath: "post/type/want",
				omit: "none",
				limit: "6",
				type: "post", 
				pagination: false,
        createButton: true,
        createButtonPath: "/new/post"
			},
      displayOptions: {
        layout: "row",
        yoursBadge: true,
        userBadge: true,
        carBadge: true,
        carDetails: true,
        dateDetails: true,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    }
  ]


  return (
    <>
      <ORSSubnav>
        <SubnavItem active to="/marketplace" label="Marketplace" />
        <SubnavItem to="/marketplace/listings" label="Listings" />
        <SubnavItem to="/marketplace/wants" label="Want-Ads" />
      </ORSSubnav>

      <ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label="Marketplace" />
      </ORSBreadcrumbs>


      <div className="relative">
        <img src={marquee} alt="Open Road Society" className="hidden md:block w-full" />
        <img src={marqueeMobile} alt="Open Road Society" className="block md:hidden w-full" />
      </div>


      <PSPage>
        {sections.map(({params, displayOptions}) => (
          <ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
        ))}
      </PSPage>
    </>
  );
};
