import React, {useState, useEffect} from "react";
import PSPage from "components/globals/ui/layout/Page";
import {ORSBreadcrumbs, BreadcrumbLink, BreadcrumbSpan, BreadcrumbSpacer} from "components/globals/ORSBreadcrumbs";
import {ORSSubnav, SubnavItem} from "components/globals/ORSSubnav";
import { useFetchData } from "helpers/utils";
import { NavLink } from "react-router-dom";


export default function CarsBrandIndex() {
	const [brands, setBrands] = useState([]);
	const url = `${process.env.REACT_APP_SERVER_URI}/api/garage/brands/all`;

	const { data, error, loading } = useFetchData(url, [0]);

	useEffect(() => {
		if (data?.brands) {
			setBrands(data.brands);
		}
	}, [data]);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			<ORSSubnav>
				<SubnavItem to="/cars" label="Cars Home" />
				<SubnavItem active to="/cars/brands" label="Brands" />
				<SubnavItem to="/cars/users" label="User Cars" />
				<SubnavItem to="/cars/spotted" label="Spotted" />
			</ORSSubnav>

			<ORSBreadcrumbs>
				<BreadcrumbLink to="/" label="Home" />
				<BreadcrumbSpacer />
				<BreadcrumbLink to="/cars" label="Cars" />
				<BreadcrumbSpacer />
				<BreadcrumbSpan label="Brands" />
			</ORSBreadcrumbs>

			<PSPage>
				<div className="grid grid-cols-2 lg:grid-cols-3 gap-4 my-4">
					{brands.map((brand) => {
						return (
							<div
								className="flex relative mx-0 my-3 shadow-[0_0_15px_10px_rgba(0,0,0,0.06)]"
								key={brand.make_handle}
							>
								<NavLink
									className="relative w-full flex w-full overflow-hidden items-stretch h-full group hover:bg-brg transition"
									to={`/cars/brands/${brand.make_handle}`}
								>
									<span className="relative overflow-hidden w-[70px] sm:w-[100px] md:w-[150px] aspect-square md:aspect-[4/3] shrink-0">
										<img
											src={`/make/${brand.make_handle}/profile.jpg`}
											className="absolute w-full h-full inset-0 object-cover block group-hover:scale-[1.1] transition"
											alt={brand.make}
										/>
									</span>

									<h4 className="text-[14px] sm:text-[20px] font-bold pl-3 text-brg group-hover:text-white transition">{brand.make}</h4>
								</NavLink>
							</div>
						);
					})}
				</div>
			</PSPage>

		</>
	);
};
